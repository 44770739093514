// ProductImageGallery.jsx
import React from "react";
import { Card, CardMedia } from "@mui/material";
import { CartState } from "../context/ShopContext";

// import inverterbattery from "./svgicons/inverterbattery.svg";
//import upsbattery from "./svgicons/upsbatterybattery.svg";

// prodImageThumbnailLoc
const ProductImageGallery = () => {
  const { cart, setCart, products, prodDetailID, setProdDetailID } =
    CartState();
  const productFound = products.find((p) => p.prodID === prodDetailID);
  // console.log("founprod==", productFound);

  // Replace the image URLs with product images
  // const images = ['https://picsum.photos/id/237/200/300'];

  return (
    <Card>
      <CardMedia
        component="img"
        height="300"
        border="1"
        image={productFound.prodImageThumbnailLoc}
        alt="Product Image"
        sx={{ objectFit: "contain", borderColor: "#25d911" }}
      />
    </Card>
  );
};

export default ProductImageGallery;
