import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

function PhoneNumber({ onValueChange, allowDecimals = true, allowNegative = false, min = -Infinity, max = Infinity, maxLength }) {
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        const newValue = event.target.value;

        // Handle empty string case for clearing the input
        if (newValue === '') {
            setValue(newValue);
            onValueChange(newValue); // Notify parent of the change
            setError(false);
            setErrorMessage('');
            return;
        }

        // Regular expression to match the input based on configuration
        const regex = allowDecimals
            ? allowNegative
                ? /^-?\d*\.?\d*$/
                : /^\d*\.?\d*$/
            : allowNegative
            ? /^-?\d*$/
            : /^\d*$/;

        // Validate the value against the regular expression and range
        if (regex.test(newValue)) {
            const numericValue = parseFloat(newValue);
            if (numericValue >= min && numericValue <= max) {
                setValue(newValue);
                onValueChange(newValue); // Notify parent of the change

                // Set error if length is not equal to maxLength
                if (newValue.length !== maxLength) {
                    setError(true);
                    setErrorMessage(`Length must be exactly ${maxLength} characters`);
                } else {
                    setError(false);
                    setErrorMessage('');
                }
            }
        } else {
            setError(true);
            setErrorMessage('Invalid input format');
        }
    };

    return (
        <div>
            <TextField
                label="Mobile Number"
                value={value}
                onChange={handleChange}
                sx={{ mb: 3, mt: 2 }}
                variant="outlined"
                fullWidth
                placeholder="Enter Mobile Number"
                required
                error={error}
            />
            {error && <FormHelperText error>{errorMessage}</FormHelperText>}
        </div>
    );
}

export default PhoneNumber;
