import * as React from "react";
import Box from "@mui/material/Box";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import Badge from "@mui/material/Badge";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import MenuIcon from "@mui/icons-material/Menu";
import { useState,useEffect } from "react";
import { Button } from "@mui/material";
// import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { InputLabel, Alert, Snackbar } from "@mui/material";
import { Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import NavBar from "./OldNavBar";
import { Enquiry } from "../DatabaseAPI/DatabaseQuery";
import { useLocation } from "react-router-dom";
import { nanoid } from "nanoid";
import FileUploadComp from "../components/FileUploadComp";
import PageFooter from "../footer/PageFooter";
import {Grid} from "@mui/material"

import { storage } from "../firebase.config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getUserEmail } from "../utils/sessionCheck";
import NavBar2 from "../components/Navbar2";
import {FORM_TYPES} from "../pages/NewLogin";
import NumericInput from "../validation/NumericInput";
import PhoneNumber from "../validation/PhoneNumber";
import AlphanumericInput from "../validation/AlphanumericInput";
import EmailInput from "../validation/EmailInput";


// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();
const MAX_COUNT = 3;

export default function EnqForm() {
  // const [anchor, setAnchor] = useState(null);
  const navigate = useNavigate();
  const [purpose, setPurpose] = useState("");
  
  const [pincode, setPincode] = useState("");
  const [file, setFile] = useState("");
  const [site, setSite] = useState("");

  const [submitFlag, setSubmitFlag] = useState(false);

  // const [fileUrl, setFileUrl] = useState("");

  
  // Show the success or failuer message as snackbar
  const [open, setOpen] = React.useState(false);
  //let ordAlertMsg = "Your Order Number - ";
  const [ordAlertMsg, setOrdAlertMsg] = React.useState("");
  const [msgType, setMsgType] = React.useState("success");
  
//   const fileStoredAt = (file) => {
//     setFileUrl(file);
//     console.log("file storage url received is >>> ", file);
//     //console.log(fileUrl);
// };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [fileLimit, setFileLimit] = useState(false);
  
  // const handleUploadFiles = (files) => {
  //   const uploaded = [...uploadedFiles];
  //   let limitExceeded = false;
  //   files.some((file) => {
  //     if (uploaded.findIndex((f) => f.name === file.name) === -1) {
  //       uploaded.push(file);
  //       if (uploaded.length === MAX_COUNT) setFileLimit(true);
  //       if (uploaded.length > MAX_COUNT) {
  //         alert(`You can only add a maximum of ${MAX_COUNT} files`);
  //         setFileLimit(false);
  //         limitExceeded = true;
  //         return true;
  //       }
  //     }
  //   });
  //   if (!limitExceeded) setUploadedFiles(uploaded);
  // };

  // const handleFileEvent = (e) => {
  //   const chosenFiles = Array.prototype.slice.call(e.target.files);
  //   if (chosenFiles && chosenFiles[0].size > 500 * 1024) {
  //     alert("File size exceeds 500kb limit. Please select a smaller file.");
  //     e.target.value = null; // Reset the input value
  //   } else {
  //     handleUploadFiles(chosenFiles);
  //   }
  //   // handleUploadFiles(chosenFiles);
  // };

  //handling change function for purpose 
  const handlePurposeChange = (event) => {
    setPurpose(event.target.value);
  };

  // const handlePincodeChange = (event) => {
  //   setPincode(event.target.value);
  // };

  // const handleCityChange = (event) => {
  //   setCity(event.target.value);
  // };

  function handleBreadCrumbClick(event) {
    // event.preventDefault();
    // console.info("You clicked a breadcrumb.");
    navigate("/");
  }

  //const [uploadFileList, setUploadFileList] = useState("");
  let uploadFileList = "[";
  let fileCounter = 1;

  const handleFileListChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile && uploadedFile.size > 200 * 1024) {
      alert("File size exceeds 200kb limit. Please select a smaller file.");
      e.target.value = null; // Reset the input value
    } else {
      //setFile(uploadedFile);
      // setFileUrl(uploadedFile);
      handleFileUpload(uploadedFile);
      // console.log("upload url retruned is#####", uploadFileURL.name );
      // if(uploadFileURL)
      // {
      //   uploadFileList += "###";
      //   uploadFileList += uploadFileURL;
      //   //setUploadFileList(uploadFileList);
      // }
    }

    console.log("Upload files list is: >>>", uploadFileList);
    // let obj = JSON.parse(uploadFileList);
    // console.log("File0 urls is >>> ", obj.file0);
  };

  const handleFileUpload = (myfile) => {

    // const [percentage, setPercentage] = useState(0);
    let percentage = 0;

    if (!myfile) {
        alert("Please upload the file first!");
    }

    console.log("storage is >> ", storage);
    console.log("file name is >> ", myfile.name);

    const storageRef = ref(storage, `/files/${myfile.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, myfile);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const prcnt = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            //setPercentage(prcnt);
            percentage = prcnt;
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log("URL is >>>>", url);
                // props.fileStoredAt(url);
                //return url;
                
                // uploadFileList += "\"file" + fileCounter + "\":"
                
                uploadFileList += url
                uploadFileList += ",";
                // fileCounter++;

                
                //console.log(url);
            });
            //props.fileStoredAt(getFileStorageUrl);

        }
    );
};

  // const handleSiteChange = (e) => {
  //   const uploadedFile = e.target.files[0];
  //   if (uploadedFile && uploadedFile.size > 500 * 1024) {
  //     alert("File size exceeds 500kb limit. Please select a smaller file.");
  //     e.target.value = null; // Reset the input value
  //   } else {
  //     setSite(uploadedFile);
  //   }
  // };

  //location function is used to extract data form the payload passed from login page 
  const location = useLocation();
  console.log(location.state.formType);
 let t = location.state.formType;
 console.log(t);

 //function to extarct keyvalue form fORM_TYPES array which is declared in the NewLogin file
 function getKeyByValue(value) {
  for (const [key, val] of Object.entries(FORM_TYPES)) {
    if (val === value) {
      return key;
    }
  }
  return null; // Return null if no key is found
}

const valueToFind = t; // Change this to the value you want to search for
const keyFound = getKeyByValue(valueToFind);
console.log(keyFound);

// const [usrMobileNo, setusrMobileNo] = useState("");
// const [inputPincode, setInputPincode] = useState("");
// const [usrName, setusrName] = useState("");
// const [city, setCity] = useState("");
// const [email, setEmail] = useState("");

//array which is used with state to take from input fields from enquiry form
const [userData, setUserData] = useState({
  name: "",
  address: "",
  phoneNumber: "",
  gstNo: "",
  pincode: "",
  city: "",
  email:""
  // added on 8th jun 2024
});

// START chnage functions for value for pincode, phonenumber,name,city,email
const handleValueChange = (value) => {
  setUserData({...userData, pincode: value});
 
};
const handleValueChange2 = (value) => {
  
  setUserData({...userData, phoneNumber: value});
 
};
const handleChangeName = (value) =>{
  
  setUserData({...userData, name: value});
};

const handleChangeCity = (value) =>{
  
  setUserData({...userData, city: value});
}

const handleChangeEmail = (value) =>{
  setUserData({...userData, email: value});
}
// END 

//checkout function for the checking validty of the all fields and validating email field
const checkout = () =>
  {
    // console.log('User Data:', userData);
    // console.log('Cart:', cart);
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(userData.phoneNumber.length!=10 || userData.name=="" || userData.city=="" ||  !emailPattern.test(userData.email)  )
      {
          
          setSubmitFlag(true);
          
      }
      else if(userData.pincode.length!=6)
        {
          setSubmitFlag(true);
          if(userData.pincode.length==0)
          {
            setSubmitFlag(false);
          }
        }
      else{
        setSubmitFlag(false);
      }
  
  }
//UseEffect that controls based on the userData array
  useEffect(()=>{
    checkout();
    console.log("Called useEffect in enquiry")
  },[userData])


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    // console.log(purpose);
    // console.log(formData.get("Name"));
    // console.log(formData.get("mobile"));
    // console.log(formData.get("email"));
    // console.log(pincode);
    // console.log(formData.get("load"));
    // console.log(formData.get("remarks"));
    // console.log(formData.get("commencement"));
    // console.log(formData.get("completion"));
    // console.log(file);
    // console.log(site);
    // console.log(uploadedFiles);\
    
  

    
    
    const enqDateAndTime = new Date();
    const data = {
      enqID: "ENQ-" + nanoid(),
      enqStatus: Number(1),
      enqTypeID: keyFound,
      enqTypeShortName: t,
      enqTitle: "",
      enqDescription: "",
      enqContactName: userData.name,
      enqContactMobileNumber: userData.phoneNumber,
      enqContactEmailID: userData.email,
      enqContactPostalAddress: "",
      enqContactCity: userData.city,
      enqContactPinCode: userData.pincode,
      enqPurpose: purpose,
      enqApprovedElectricLoad: formData.get("load"),
      enqTargetStartDate: formData.get("commencement"),
      enqTargetEndDate: formData.get("completion"),
      enqImageURLs: (uploadFileList.substring(0,uploadFileList.length-1)+ "]"),
      enqTotalNumberOfMaintainItems: "",
      enqAdditionalRemarks: formData.get("remarks"),
      enqDateAndTime
    };
    // console.log(data);
    await Enquiry(data);
    // try {
    //   const productList = await FilteredCards(
    //     data.get("type"),
    //     data.get("price"),
    //     data.get("brand"),
    //     data.get("city"),
    //     data.get("pincode")
    //   );
    //   updateProducts(productList);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
    setOpen(true);
    setSubmitFlag(true);
  };

 
   const purposeOp = process.env.REACT_APP_PURPOSE.split(",");

  // method for snackbar alert
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //snack bar code completed
//  const [s,setS] = useState(false);
  // React.useEffect(()=>{

  //   console.log("Ongrid Cuurent email : >>", getUserEmail());
  //   if (! getUserEmail()){
  //     console.log("Ongrid you are logged in with : >>", getUserEmail());
  //     setS(true);
  //     navigate("/login",{state:{s}});

  //   }
  // }, []);
  
  useEffect(() => {
    const email = getUserEmail();
    console.log("Ongrid Current email : >>", email);

    if (!email) {
        console.log("Ongrid you are logged in with : >>", email);
       
        navigate("/login",{state:{t}}); // Pass state directly
    }
    else{
      navigate("/enq", { state:{formType:t} });
      console.log("hello")
    }
}, [navigate]);

  return (
    <>
    <Box component="form" onSubmit={handleSubmit}>
      
    <NavBar2 cartDisplayProp={'none'}
              showMenuBarProp={'show'}
            />

      <Box role="presentation" onClick={handleBreadCrumbClick} sx={{ p: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="text.primary">Solar</Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{m:5, border:2, borderColor:"lightgreen", maxWidth:"80%"}}>

      <Stack spacing={2} sx={{ px: 10 }}>
        <Typography
          variant="h4"
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: "center",
            m: 4,
            color: "blue",
          }}
        >
          {location.state.formType} - Enquiry Form
        </Typography>
       
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Purpose</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={purpose}
            label="Purpose"
            onChange={handlePurposeChange}
            required
          >
            {purposeOp &&
              purposeOp.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            {/* <MenuItem value={"Commercial"}>Commercial</MenuItem>
            <MenuItem value={"Residential"}>Residential</MenuItem> */}
          </Select>
        </FormControl>
       

        <AlphanumericInput 
                
                onValueChange={handleChangeName} 
                label="Contact Name"
                />
       
            <PhoneNumber
                allowDecimals={false}
                allowNegative={false}
                min={0}
                max={10000000000}
                onValueChange={handleValueChange2}
                maxLength ={10}
              />



            <EmailInput 
                
                onValueChange={handleChangeEmail} 
                label="Contact Email-ID"
            />

        {/* <TextField
          id="outlined-basic-pincode"
          label="City of Project Implementation (Pincode)"
          variant="outlined"
        /> */}
        {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label-pincode">City</InputLabel> */}
        {/* <TextField
          id="city"
          label="City"
          name="city"
          variant="outlined"
          required
          onChange={handleCityChange}
          // value={resetAll}
        /> */}

            <AlphanumericInput 
                
                onValueChange={handleChangeCity} 
                label="City"
            />
        
        <NumericInput
                allowDecimals={false}
                allowNegative={false}
                min={0}
                max={1000000}
                onValueChange={handleValueChange}
            />

{/* START - Commented below code for release one */}

        {/* <TextField
          id="outlined-basic-approved"
          label="Approved Electric Load (Optional)"
          name="load"
          variant="outlined"
          // value={resetAll}
        /> */}

{/* END - Commented above code for release one */}


        <TextField
          id="outlined-basic-remark"
          label="Additional Remarks/ Requirements"
          name="remarks"
          variant="outlined"
          multiline
          // value={resetAll}
        />
        {/* <TextField
          id="outlined-basic-commencement"
          label="Target Project Commencement Date (optional)"
          name="commencement"
          variant="outlined"
        />
        <TextField
          id="outlined-basic-complete"
          label="Target Project Completion Date (optional)"
          name="completion"
          variant="outlined" */}
        {/* /> */}

 {/* START - Commented below code for release one */}
{/*         
        <Stack spacing={3} sx={{m:3, border:1, borderColor:"lightGreen", p:2}}>
          <Typography variant="subtitle"> <b>Attachements:</b></Typography>
            <Grid container spacing={5}>
              <Grid xs={3}>
                <label htmlFor="bill">Electric Bill copy:</label>
              </Grid>
              <Grid>
                  <input
                  type="file"
                  id="bill"
                  onChange={handleFileListChange}
                  accept=".png, .jpg, .jpeg,.doc,.docx, .pdf"
                  maxSize="200KB"
                />
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid xs={3}>
                <label htmlFor="site">Photos for Project site:</label>
            
              </Grid>
              <Grid>
                <input type="file" 
                  id="site" onChange={handleFileListChange} 
                  accept=".png, .jpg, .jpeg,.doc,.docx, .pdf"
                  maxSize="200KB"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid xs={3}>
                <label htmlFor="site">Additional File 1:</label>
            
              </Grid>
              <Grid>
                <input type="file" 
                  id="site" onChange={handleFileListChange} 
                  accept=".png, .jpg, .jpeg,.doc,.docx, .pdf"
                  maxSize="200KB"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid xs={3}>
                <label htmlFor="site">Additional File 2:</label>
            
              </Grid>
              <Grid>
                <input type="file" 
                  id="site" onChange={handleFileListChange} 
                  accept=".png, .jpg, .jpeg,.doc,.docx, .pdf"
                  maxSize="200KB"
                />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid xs={3}>
                <label htmlFor="site">Additional File 3:</label>
            
              </Grid>
              <Grid>
                <input type="file" 
                  id="site" onChange={handleFileListChange} 
                  accept=".png, .jpg, .jpeg,.doc,.docx, .pdf"
                  maxSize="200KB"
                />
              </Grid>
            </Grid>
        </Stack> */}

{/* END - Commented above code for release one */}

       {/* <FileUploadComp fileStoredAt={fileStoredAt} /> */}

{/* 
        <div className="uploaded-files-list">
          {uploadedFiles.map((file) => (
            <div>{file.name}</div>
          ))}
        </div> */}


        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          sx={{ px: 10, pb: 4 }}
        >
          <Button type="submit" variant="contained" color="success"
          disabled={submitFlag}
          >
            Submit
          </Button>
          <Button variant="contained" color="error"
          onClick={() => window.location.reload(false)}>
            Reset
          </Button>
        </Stack>
      </Stack>
      </Box>
    </Box>

    {/* display the order number to user */}
    <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={msgType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {/* This is a success Alert inside a Snackbar! */}
          Record has been added
        </Alert>
      </Snackbar>

    <PageFooter />
    </>
  );
}
