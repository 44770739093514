// EVProductPage.jsx
import React from "react";
import { Container, Paper, Typography, Grid, Stack, Button } from "@mui/material";
import ProductDetails from "./productdetails";
import ProductImageGallery from "./productimages";
import NavBar from "./OldNavBar";
import Switch from "@mui/material/Switch";
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useNavigate } from "react-router-dom";
import NavBar2 from "../components/Navbar2";

const buttonstyle = { marginLeft: "5px", marginRight: "5px",marginTop:"5px"};

const EVProductDetails = () => {

    const navigate = useNavigate();
  return (
    <>
      <NavBar2 />
      <Container maxWidth="lg">
        <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
          <Typography variant="h4" gutterBottom>
            Product Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ProductImageGallery />
              <Stack direction="column" my="10px">
                <item>
                    <Typography variant="h6">
                    <Button style={buttonstyle} 
                    variant="contained"
                    color="success"><ContactSupportIcon></ContactSupportIcon>Contact for more Information</Button>
                    </Typography>
                    <Typography variant="h6">
                    <Button style={buttonstyle} 
                    variant="contained"
                    color="success" 
                    onClick={()=>navigate("/testride")}><BookOnlineIcon></BookOnlineIcon>Book a test ride</Button>
                    </Typography>
                    <Typography variant="body">
                    *Terms and conditions apply
                    </Typography>
                    
                  {/* <Stack direction="row">
                    <Switch defaultChecked />
                    <Typography variant="h6" paragraph>
                      Installation Required
                    </Typography>
                  </Stack>
                </item>
                <item>
                  <Stack direction="row">
                    <Switch defaultChecked />
                    <Typography variant="h6" paragraph>
                      Old Battery return
                    </Typography>
                  </Stack>
                </item>
                <item>
                  <Stack direction="row">
                    <Switch defaultunChecked />
                    <Typography variant="h6" paragraph>
                      COD{" "}
                    </Typography>
                  </Stack> */}
                </item>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <ProductDetails />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default EVProductDetails;
