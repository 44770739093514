import { firestore } from "../firebase.config";
import {
  doc,
  collection,
  getDoc,
  setDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAt,
  addDoc,
  updateDoc,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
// import { useState } from "react";
import {

  
  getLast,
  getLoadMore,
  setLast,
  setLoadMore,
  setLastOrder,
  getLastOrder,
  getSessionProdListByType,
  setLastEnquiry,
  getLastEnquiry,
} from "../utils/sessionCheck";
import { useState } from "react";

export const IdMatcher = async (row) => {
  return await getDocs(
    query(
      collection(firestore, "Product_Master"),
      where("prodID", "==", row.prodID)
    )
  );
};

//Enquiry Inserting Function
export const Enquiry = (data) => {
  // console.log("In", data);
  return addDoc(collection(firestore, "EnquiryTransaction"), data);
};

//signup data Function
export const SignUp = async (userEmail,data) => {
  console.log("In sign up data query", data);
  const docRef = doc(firestore, "UserProfileMaster", userEmail);
  // return addDoc(collection(firestore, "UserProfileMaster"), data);
  try {
    // Set the document with the provided data
    await setDoc(docRef, data);
    console.log("Document written with ID:", userEmail);
    return true;
  } catch (e) {
    console.error("Error adding document: ", userEmail);
    return false;
  }
};

//function for getting Order data 
export const OrderMaster = (data) => {
  console.log("$$$$$$$$ Order data getting added to table >>>>", data);
  return addDoc(collection(firestore, "OrderMaster"), data);
};

//function for loading Product data
export const ProductMaster = () => {
  return doc(collection(firestore, "Product_Master"));
};

export const ProductMasterID = (docId) => {
  return doc(collection(firestore, "Product_Master"), docId);
};

// START - General upload date from excel based on the colelction name sent across
export const UpdateMasterData = (collectionNm) => {
  console.log("Inside UpdateMasterData");
  return doc(collection(firestore, collectionNm));
}

export const collectionIdMatcher = async (row, collectionNameIDFld, collectionNm) => {

  console.log("Inside collection ID Matcher");
  return await getDocs(
    query(
      collection(firestore, collectionNm),
      where(collectionNameIDFld, "==", row.prodID)
    )
  );
};

//END

//Pincode Checker function start
export const PinIdMatcher = async (row) => {
  // return await getDocs(
  //   query(collection(firestore, "Pincodes"), where("pinID", "==", row.pinID))
  // );
  return await getDoc(doc(firestore, "PinCode_Master", String(row.pinID)));
};

export const Pincode = (id) => {
  return doc(firestore, "PinCode_Master", id);
};

export const PincodeID = (docId) => {
  return doc(firestore, "PinCode_Master", docId);
};

//end

// START - getAllDelveryPinCodes will return all the pincodes from PINCODE_MASTER where isActivate flag is true
// it will return all the matching entries as array

export const getAllDeliveryPinCodes = async () => {
  const pinCodesDetailsArray = []; // this will store the active pincode, ites associated sccesspartner address,
  // email, phone number in JSON format
  const querySnapshot = await getDocs(
    collection(firestore, "PinCode_Master"),
    where("isDeliveryLocationActive", "==", "Y")
  );

  querySnapshot.forEach((doc) => {
    const pinCodeRecord = {
      pincode: doc.data().pinCodes,
      successPartnerName: doc.data().successPartnerName,
      successPartnerAddress: doc.data().successPartnerAddress,
      successPartnerEmailID1: doc.data().successPartnerEmailID1,
      successPartnerEmailID2: doc.data().successPartnerEmailID2,
      successPartnerMobileNo: doc.data().successPartnerMobileNo,
      successPartnerSPGrpID: doc.data().spGrpID,
    };
    pinCodesDetailsArray.push(JSON.stringify(pinCodeRecord));
  });

  // console.log("Pincode array is >> ", pinCodesDetailsArray)
  return pinCodesDetailsArray;
};


//getting order list function for admin 
export const OrderList = async (spGroupID) => {
 

  const CardList = [];

  let q = "";

  try {
    if (spGroupID.includes("INTEROPS") || spGroupID.includes("INTERADM")) {
      // console.log("Inside INTEROPS or INTERADm >>>");

      q = query(
        collection(firestore, "OrderMaster"),
        //  limit(size)
        where("ordIsDeleted", "==", false),
        //where("ordSPGrpID", "==", spGroupID),
        orderBy("ordDateAndTime", "desc"),
        limit(5)
      );
    } else {
      // console.log("Inside SPUserOps >>>",spGroupID);

      q = query(
        collection(firestore, "OrderMaster"),
        where("ordSPGrpID", "==", spGroupID),
        orderBy("ordDateAndTime", "desc"),
        limit(5)
      );
    }
    try{

    
    // console.log("Query Running")
    const querySnapshot = await getDocs(q);

    const currentDateTime = new Date();
    const maxIndex = querySnapshot.docs.reduce((oldestDate, doc) => {
      const orderDate = doc.data().ordDateAndTime;

      if (orderDate) {
        const currentDateObj = orderDate.toDate();
        // console.log(currentDateObj);
        if (!oldestDate || currentDateObj < oldestDate) {
          oldestDate = currentDateObj;
        }
      }

      return oldestDate;
    }, currentDateTime);
    // console.log(maxIndex);
    setLastOrder(maxIndex);

    querySnapshot.forEach((doc) => {
      CardList.push({ id: doc.id, ...doc.data() });
    });

    return CardList;
  } catch (err) {
    console.log("Error in getting OrderList",err);
    console.error();
  }
  } catch (err) {
    console.log("Error in getting OrderList");
    console.error();
  }
};
//enquiry List for getting enquiry data 
export const EnquiryList = async (spGroupID) => {
  const EnqList = [];

  let q = "";
  try {
    if (spGroupID.includes("INTEROPS") || spGroupID.includes("INTERADM")) {
      // console.log("Inside INTEROPS or INTERADm >>>");

      q = query(
        collection(firestore, "EnquiryTransaction"),
        //  limit(size)
       
        //where("ordSPGrpID", "==", spGroupID),
        orderBy("enqDateAndTime", "desc"),
        limit(5)
      );
    }
    
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   EnqList.push({ id: doc.id, ...doc.data() });
    // });
    try{

    
      // console.log("Query Running")
      const querySnapshot = await getDocs(q);

     if(querySnapshot.empty)
     {
      console.log("Displaying Enquiry list",EnqList);
      return;

     }
  
      const currentDateTime = new Date();
      const maxIndex = querySnapshot.docs.reduce((oldestDate, doc) => {
        const orderDate = doc.data().enqDateAndTime;
  
        if (orderDate) {
          const currentDateObj = orderDate.toDate();
          // console.log(currentDateObj);
          if (!oldestDate || currentDateObj < oldestDate) {
            oldestDate = currentDateObj;
          }
        }
  
        return oldestDate;
      }, currentDateTime);
      // console.log(maxIndex);
      setLastEnquiry(maxIndex);
  
      querySnapshot.forEach((doc) => {
        EnqList.push({ id: doc.id, ...doc.data() });
      });
  
      return EnqList;
    } catch (err) {
      console.log("Error in getting Enquiry List",err);
      console.error();
    }
    

    
  } catch (error) {
    console.log("Error in getting EnqList",error);
    console.error();
  }
}

// START - OrderListCustomer function returns the orders added by customer or any authentic user after clicking on
// Track Orders from menu

export const OrderListCustomer = async (c_Email) => {
  const CardList = [];

  try {
    const q = query(
      collection(firestore, "OrderMaster"),
      //  limit(size)
      where("ordIsDeleted", "==", false),
      where("ordEmailID", "==", c_Email),
      orderBy("ordDateAndTime", "desc"),
      limit(5)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      CardList.push({ id: doc.id, ...doc.data() });
    });
    return CardList;
  } catch (e) {
    console.log("Track order got an error");
    console.error();
  }
};

//searching Order based on email and enqid
export const OrderItem = async (orderID, EmailID) => {
  const CardList = [];
  const q = query(
    collection(firestore, "OrderMaster"),
    where("ordID", "==", orderID),
    where("ordEmailID", "==", EmailID),
    where("ordIsDeleted", "==", false)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    CardList.push({ id: doc.id, ...doc.data() });
  });

  return CardList;
};

//searching enquiry based on email and enqid
export const EnquiryItem = async (enqID, EmailID) => {
  const CardList = [];
  const q = query(
    collection(firestore, "EnquiryTransaction"),
    where("ordID", "==", enqID),
    where("ordEmailID", "==", EmailID),
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    CardList.push({ id: doc.id, ...doc.data() });
  });

  return CardList;
};

// START - EditOrder - Edits the order in Admin / Ops flow

export const EditOrder = async (orderID) => {
  const CardList = [];
  const q = query(
    collection(firestore, "OrderMaster"),
    where("ordID", "==", orderID)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    CardList.push({ id: doc.id, ...doc.data() });
  });

  return CardList;
};

// START - EditEnquiry - Edits the Enquiry in Admin 
export const EditEnquiry = async (EnquiryID) => {
  const EnqListList = [];
  const q = query(
    collection(firestore, "EnquiryTransaction"),
    where("enqID", "==", EnquiryID)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    EnqListList.push({ id: doc.id, ...doc.data() });
  });

  return EnqListList;
};


// START - EditOrderProducts - Edits the products in "order" during  Admin / Ops flow

export const EditOrderProducts = async (productID) => {
  const q = query(
    collection(firestore, "Product_Master"),
    where("prodID", "in", productID)
  );
  const CardList = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    CardList.push({ id: doc.id, ...doc.data(), qty: 1 });
  });
  return CardList;
};

// START - UpdateOrder - updates existing order in Admin / Ops flow
export const UpdateOrder = async (docID, data) => {
  // const q = query(
  //   collection(firestore, "OrderMaster"),
  //   where("ordID", "==", orderID)
  // );
  // const querySnapshot = await getDocs(q);
  const orderRef = doc(firestore, "OrderMaster", docID);
  await updateDoc(orderRef, data);
};

// START - UpdateEnquiry - updates existing Enquiry in Admin 
export const UpdateEnquiry = async (docID, data) => {
  // const q = query(
  //   collection(firestore, "OrderMaster"),
  //   where("ordID", "==", orderID)
  // );
  // const querySnapshot = await getDocs(q);
  const orderRef = doc(firestore, "EnquiryTransaction", docID);
  await updateDoc(orderRef, data);
};

// START - LoadMoreOrderList - Loads more records during Admin / Ops flow

export const LoadMoreOrderList = async () => {
  const CardList = [];
  try {
  const last = getLastOrder();
  const dateTimeObj = new Date(last);
  // console.log(dateTimeObj);
  // console.log(last);
  const q = query(
    collection(firestore, "OrderMaster"),
    where("ordIsDeleted", "==", false),
    orderBy("ordDateAndTime", "desc"),
    startAt(dateTimeObj),
    limit(5)
  );
  const querySnapshot = await getDocs(q);
  console.log(querySnapshot);
  console.log("Console Querysize",querySnapshot.size);

  const currentDateTime = new Date();
  const maxIndex = querySnapshot.docs.reduce((oldestDate, doc) => {
    const orderDate = doc.data().ordDateAndTime;

    if (orderDate) {
      const currentDateObj = orderDate.toDate();
      // console.log(currentDateObj);
      if (!oldestDate || currentDateObj < oldestDate) {
        oldestDate = currentDateObj;
      }
    }

    return oldestDate;
  }, currentDateTime);
  console.log(maxIndex);
  setLastOrder(maxIndex);

  querySnapshot.forEach((doc) => {
    CardList.push({ id: doc.id, ...doc.data() });
  });
}catch(e) {
  console.log("LoadMoreOrderList",e);
}

  return CardList;
};

// loading more enquirys 
export const LoadMoreEnquiryList = async () => {
  // const CardList = [];
  const EnqList = [];
  try {
  const last = getLastEnquiry();
  
  const dateTimeObj = new Date(last);
  // console.log(dateTimeObj);
  // console.log(last);
  const q = query(
    collection(firestore, "EnquiryTransaction"),
    
    orderBy("enqDateAndTime", "desc"),
    startAt(dateTimeObj),
    limit(5)
  );
  const querySnapshot = await getDocs(q);
  console.log(querySnapshot);
  console.log("Console Querysize",querySnapshot.size);

  const currentDateTime = new Date();
  const maxIndex = querySnapshot.docs.reduce((oldestDate, doc) => {
    const orderDate = doc.data().enqDateAndTime;

    if (orderDate) {
      const currentDateObj = orderDate.toDate();
      // console.log(currentDateObj);
      if (!oldestDate || currentDateObj < oldestDate) {
        oldestDate = currentDateObj;
      }
    }

    return oldestDate;
  }, currentDateTime);
  console.log(maxIndex);
  setLastEnquiry(maxIndex);

  querySnapshot.forEach((doc) => {
    EnqList.push({ id: doc.id, ...doc.data() });
  });
}catch(e) {
  console.log("LoadMoreOrderList",e);
}

  return EnqList;
};

//loading order list for customer in its profile 
export const LoadMoreOrderListCust = async () => {
  const CardList = [];
  try {
  const last = getLastOrder();
  const dateTimeObj = new Date(last);
  // console.log(typeof dateTimeObj);
  const q = query(
    collection(firestore, "OrderMaster"),
    //  limit(size)
    where("ordIsDeleted", "==", false),
    orderBy("ordDateAndTime", "desc"),
    startAt(dateTimeObj),
    limit(5)
  );
  const querySnapshot = await getDocs(q);
  console.log(querySnapshot);
  console.log("Console Querysize",querySnapshot.size);

  const currentDateTime = new Date();
  const maxIndex = querySnapshot.docs.reduce((oldestDate, doc) => {
    const orderDate = doc.data().ordDateAndTime;

    if (orderDate) {
      const currentDateObj = orderDate.toDate();
      // console.log(currentDateObj);
      if (!oldestDate || currentDateObj < oldestDate) {
        oldestDate = currentDateObj;
      }
    }

    return oldestDate;
  }, currentDateTime);
  setLastOrder(maxIndex);

  querySnapshot.forEach((doc) => {
    console.log("docs: ",doc.data())
    CardList.push({ id: doc.ordID, ...doc.data() });
  });
}catch(e) {
  console.log("LoadMoreOrderList",e);
}

  return CardList;
};

export const GridCardExpand = async (iter, size, type) => {
  const data = {
    Category: type,
    Type: null,
    Price: null,
    Brand: null,
    Pincode: null,
  };
  setLoadMore(data);
  // const last = getLast("Last");
  // console.log(last);
  const CardList = [];
  if (iter === 1) {
    const q = query(
      collection(firestore, "Product_Master"),
      where("prodProductCategory", "==", type),
      // where("index", ">", parseInt(last)),
      orderBy("index"),
      limit(size)
    );
    const querySnapshot = await getDocs(q);
    // console.log(querySnapshot);
    // Get the last visible document
    // const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const maxIndex = querySnapshot.docs.reduce((max, doc) => {
      const index = doc.data().index;
      return Math.max(max, index);
    }, 0);
    // console.log("last", maxIndex);
    setLast(maxIndex);

    querySnapshot.forEach((doc) => {
      CardList.push({ id: doc.id, ...doc.data(), qty: 1 });
    });
  } else {
    // Construct a new query starting at this document,
    // get the next 25 cities.
    const last = parseInt(getLast()) + 1;
    // console.log("In", last);
    const q = query(
      collection(firestore, "Product_Master"),
      where("prodProductCategory", "==", type),
      // where("index", ">", parseInt(last)),
      orderBy("index"),
      startAt(last),
      limit(size)
    );
    const querySnapshot = await getDocs(q);
    // console.log(querySnapshot);
    // Get the last visible document
    // const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const maxIndex = querySnapshot.docs.reduce((max, doc) => {
      const index = doc.data().index;
      return Math.max(max, index);
    }, 0);
    // console.log("last", maxIndex);
    setLast(maxIndex);

    querySnapshot.forEach((doc) => {
      CardList.push({ id: doc.id, ...doc.data(), qty: 1 });
    });
  }

  return CardList;
};

//Function which fetch data when product is selected from home page or landing Page
export const GridCards = async (size, type) => {
  const q = query(
    collection(firestore, "Product_Master"),
    where("prodUsageType", "==", type),
    orderBy("prodPromotionPriority"),
    limit(size)
  );
  const CardList = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
 
    CardList.push({ id: doc.id, ...doc.data(), qty: 1 });
    // console.log(doc.id, " => ", doc.data());
  });
  return CardList;
};

//Card Filtering for filter page or Product page filtering
export const FilteredCards = async (cat, type, price, brand, pincode) => {
  const data = {
    Category: cat,
    Type: type,
    Price: price,
    Brand: brand,
    Pincode: pincode,
  };
  setLoadMore(data);
  let q;
  q = query(collection(firestore, "Product_Master"));
  // Apply filters for Type, Brand, and Price as available
  if (type && type !== "All") {
    q = query(q, where("prodUsageType", "==", type));
  }
  if (brand && brand !== "All") {
    q = query(q, where("prodBrandID", "==", parseInt(brand)));
  }
  if (price) {
    // Adjust the comparison as needed, e.g., range queries
    // q = query(q, where('price', '<=', filters.price));

    if (price === "1") {
      q = query(q, where("prodMRP", ">", 0));
    } else if (price === "2") {
      q = query(q, where("prodMRP", "<", 2000));
    } else if (price === "3") {
      q = query(q, where("prodMRP", ">=", 2000), where("prodMRP", "<=", 10000));
    } else {
      q = query(q, where("prodMRP", ">", 10000));
    }
  }
  // q = query(q, orderBy("index"), limit(12));
  

  const sessiontype = getSessionProdListByType();
  if(sessiontype)
  {
    q = query(q, orderBy("prodPromotionPriority"), limit(10));
  }
  else{
    q = query(q, orderBy("index"), limit(10));
  }


  let CardList = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    CardList.push({ id: doc.id, ...doc.data(), qty: 1 });
    // console.log(doc.id, " => ", doc.data());
  });

  const maxIndex = querySnapshot.docs.reduce((max, doc) => {
    const index = doc.data().index;
    return Math.max(max, index);
  }, 0);
  // console.log("last", maxIndex);
  setLast(maxIndex);

  if (pincode && pincode.length) {
    // Now this reassignment is allowed because CardList is declared with let
    CardList = CardList.filter((product) => {
      const productPincodes = JSON.parse(product.pincodes); // Assuming product.pincodes is a JSON string of pincodes
      return !productPincodes.some((Pincode) => pincode.includes(Pincode));
    });
  }

  return CardList;
};

//load more functionality for loading further data
export const LoadMoreCards = async () => {
  // const data = {Type: type, Price: price, Brand: brand, Pincode: pincode};
  const data = JSON.parse(getLoadMore());
  const cat = data.Category;
  const type = data.Type;
  const price = data.Price;
  const brand = data.Brand;
  const pincode = data.Pincode;
  // console.log(type, price, brand, pincode);

  const last = parseInt(getLast()) + 1;

  let q;
  q = query(collection(firestore, "Product_Master"));
  // Apply filters for Type, Brand, and Price as available
  if (cat && cat !== "All") {
    q = query(q, where("prodProductCategory", "==", cat));
  }
  if (type && type !== "All") {
    q = query(q, where("prodUsageType", "==", type));
  }
  if (brand && brand !== "All") {
    q = query(q, where("prodBrandID", "==", parseInt(brand)));
  }
  if (price) {
    // Adjust the comparison as needed, e.g., range queries
    // q = query(q, where('price', '<=', filters.price));

    if (price === "1") {
      q = query(q, where("prodMRP", ">", 0));
    } else if (price === "2") {
      q = query(q, where("prodMRP", "<", 2000));
    } else if (price === "3") {
      q = query(q, where("prodMRP", ">=", 2000), where("prodMRP", "<=", 10000));
    } else {
      q = query(q, where("prodMRP", ">", 10000));
    }
  }

  q = query(q, orderBy("index"), startAt(last), limit(5));

  let CardList = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    CardList.push({ id: doc.id, ...doc.data(), qty: 1 });
  });

  const maxIndex = querySnapshot.docs.reduce((max, doc) => {
    const index = doc.data().index;
    return Math.max(max, index);
  }, 0);
  // console.log("last", maxIndex);
  setLast(maxIndex);

  if (pincode && pincode.length) {
    // Now this reassignment is allowed because CardList is declared with let
    CardList = CardList.filter((product) => {
      const productPincodes = JSON.parse(product.pincodes); // Assuming product.pincodes is a JSON string of pincodes
      return !productPincodes.some((Pincode) => pincode.includes(Pincode));
    });
  }

  if (CardList.length === 0) {
  }

  return CardList;
};

// for Admin screens get the logged in user role and Group id to provision access at order processing screen
export const getLoginRoleGrp4AdminScreens = async (spUserEmaildRec) => {
  let loginRoleGrpAtAdminScreens = [];

  // console.log(
  //   "Inside get login role query method and email received is >>>",
  //   spUserEmaildRec
  // );  
  const sanitizedEmail = spUserEmaildRec;
    
  // console.log("Sanitized email is >>>", sanitizedEmail);
  try {
    if (spUserEmaildRec) {
      const db = collection(firestore, "SuccessPartnerAccess")
      const q = query(db, where("SPUserEmailId", "==", sanitizedEmail));
      // console.log("Query is >> ", q);
      const querySnapshot = await getDocs(q);
      // console.log("Number of documents found: ", querySnapshot.size);

      // console.log("Query snapshot is >> ", querySnapshot);  
      querySnapshot.forEach((doc) => {
        // console.log("Inside query snashot iteration >>> ", doc.data());
        loginRoleGrpAtAdminScreens.push({
          // spUserGrpId: querySnapshot.doc[0].data.SPUserGrpID,
          // spUserRole: querySnapshot.doc[0].data.SPUserRole
          spUserGrpId: doc.data().SPUserGrpID,
          spUserRole: doc.data().SPUserRole,
        });
        // console.log(
        //   "LoginRole Data returned for Admin screen is >>",
        //   loginRoleGrpAtAdminScreens
        // );
      });
      return loginRoleGrpAtAdminScreens;
    }
  } catch (error) {
    console.log("Error while fetching the SPGroup ID from DB");
    console.error(error);
  }
};


//User Profile Checking for valindating user

export const getUserProfile = async (email) => {
  //  const email = "adityaverma2369@gmail.com";
    const q = query(
      collection(firestore, "UserProfileMaster"),
      where("Email", "==", email),
      // where("index", ">", parseInt(last)),
      // orderBy("index"),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    const prod = querySnapshot.docs.map(doc=>({...doc.data()}));
    const pin = prod.map(data =>data.DefaultCustomerPincode);
     return pin
  }





/*created function which checks for the query that weather data is within ProdSPMappingCollection so here
  we going to call this function in ProductThumbNICard.js at line 58 on handleAddCart Function
*/

export const isProductAvailableAtPincode = async (productId, deliveryPincode) => {
  console.log('Inside isProductAvailableAtPincode');
 
  console.log(productId);
  console.log(deliveryPincode);
  const q = query(
    collection(firestore, "ProdSPMappingCollection"),
    where("PinCode", "==", deliveryPincode),
    where("ProdID", "==", productId),
     
    
  );

  try {
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.size)

    const data = querySnapshot.docs.map(doc =>({id:doc.id,...doc.data()}));
    
    
    // Check if any document exists
    if (!querySnapshot.empty) {
      
      return true;
    } else {
      
      return false;
    }
    
  } catch (error) {
    console.error('Error checking product availability:', error);
    return false;
  }
};


// export const incrementCountOfOrder = async (allproductid,allproductqty) =>{
//   console.log(allproductid);
//   console.log(allproductqty);

//   try {
//     const productdetails = collection(firestore,"ProdSPMappingCollection");
//     const q = query(productdetails, where('ProdID', 'in', allproductid),
//     orderBy("CountOfOrders","asc"),
//     limit(allproductid.length) 
//   );

//     const querySnapshot = await getDocs(q);
//     const products = querySnapshot.docs.map(doc => ({
//       id: doc.id,
//       ...doc.data()
//     }));
//     console.log('Fetched products:', products);
//     //updating the count 
//     const updatePromises = products.map((product, index) => {
//       const newCountOfOrders = (product.CountOfOrders || 0) + (allproductqty[index] || 0);
//       const productRef = doc(firestore, "ProdSPMappingCollection", product.id);
//       return updateDoc(productRef, { CountOfOrders: newCountOfOrders });
//     });

//     await Promise.all(updatePromises);
//     console.log('CountOfOrders updated successfully');

  
//   } 
  
//   catch (error) {
//     console.error('Error fetching products:', error);
//   }
// }

//Order Count incrementing function after Placing Order

export const incrementCountOfOrder = async (allproductid, allproductqty) => {
  console.log(allproductid);
  console.log(allproductqty);

  try {
    const productdetails = collection(firestore, "ProdSPMappingCollection");
    const q = query(
      productdetails,
      where('ProdID', 'in', allproductid),
      orderBy("CountOfOrders", "asc")
    );

    const querySnapshot = await getDocs(q);
    const products = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    console.log('Fetched products:', products);

    // Create a mapping of product IDs to their respective quantities
    const productQtyMap = allproductid.reduce((acc, id, index) => {
      acc[id] = allproductqty[index] || 0;
      return acc;
    }, {});

    // Loop through each product and update its CountOfOrders
    for (const product of products) {
      const qtyToAdd = productQtyMap[product.ProdID] || 0;

      if (qtyToAdd > 0) {
        const newCountOfOrders = (product.CountOfOrders || 0) + qtyToAdd;
        const productRef = doc(firestore, "ProdSPMappingCollection", product.id);
        await updateDoc(productRef, { CountOfOrders: newCountOfOrders });
        console.log('CountOfOrders updated successfully for product:', product.id);

        // Remove the updated product ID from the mapping
        delete productQtyMap[product.ProdID];
      }
    }

    // If there are any remaining product IDs in the mapping, log an error or warning
    if (Object.keys(productQtyMap).length > 0) {
      console.warn('Some products were not updated:', productQtyMap);
    }

  } catch (error) {
    console.error('Error updating CountOfOrders:', error);
  }
};



//Order Status Updating by admin
export const UpdateOrderStatus = async (docID, data) => {
  // console.log("DocID: ", docID, "Data: ", data);
let data2 = parseInt(data, 10);
  const orderRef = doc(firestore, "OrderMaster", docID);
  // console.log(orderRef);
  try {
    await updateDoc(orderRef, {
      ordOverallStatus: data2,
    });
    // console.log("Order status updated successfully!");
  } catch (error) {
    alert("Error updating order status: ", error);
    console.error("Error updating order status: ", error);
  }
};


//function for updating 
export const UpdateEnquiryStatus = async (docID, data) => {
  // console.log("DocID: ", docID, "Data: ", data);
let data2 = parseInt(data, 10);
  const orderRef = doc(firestore, "EnquiryTransaction", docID);
  // console.log(orderRef);
  try {
    await updateDoc(orderRef, {
      enqStatus: data2,
    });
    // console.log("Order status updated successfully!");
  } catch (error) {
    alert("Error updating order status: ", error);
    console.error("Error updating order status: ", error);
  }
};

//Function for updating SP values
export const UpdateOrderSP = async (docID, data,prevSP,prodid,prodqty) => {
  // console.log("DocID: ", docID, "Data: ", data);
  console.log("prevSP",prevSP , "prodid",prodid,"prodqty",prodqty);
  const orderRef = doc(firestore, "OrderMaster", docID);
  // console.log(orderRef);
  try {
    await updateDoc(orderRef, {
      ordSPGrpID: data,
    });
    // console.log("Order status updated successfully!");
  } catch (error) {
    alert("Error updating order status: ", error);
    console.error("Error updating order status: ", error);
  }
  
  const UpdatingRef = collection(firestore,'ProdSPMappingCollection');
  const DecrementproductsQuery = query(
    UpdatingRef,
    where('ProdID', '==', prodid),
    where('SPID', '==', prevSP)
);

  try {
    const querySnapshot = await getDocs(DecrementproductsQuery);
    if (querySnapshot.empty) 
    {
      console.log('No matching documents found.');
      return;
    }
    const batch = writeBatch(firestore);
    querySnapshot.forEach(doc => {
      const docRef = doc.ref; // Reference to the current document
      const newCount = doc.data().CountOfOrders - prodqty;

      // Ensure CountOfOrder does not go below zero
      if (newCount >= 0) {
          batch.update(docRef, { CountOfOrders: newCount });
      } else {
          console.log(`CountOfOrder cannot go below zero for document: ${doc.id}`);
      }
  });
  await batch.commit();
  console.log('CountOfOrder decremented successfully.');
    
  } catch (error) {
    console.error('Error decrementing CountOfOrder:', error);
  }

//Updating Value for new SPUser
  const IncrementproductsQuery = query(
    UpdatingRef,
    where('ProdID', '==', prodid),
    where('SPID', '==', data)
);

  try {
    const querySnapshot = await getDocs(IncrementproductsQuery);
    if (querySnapshot.empty) 
    {
      console.log('No matching documents found.');
      return;
    }
    const batch = writeBatch(firestore);
    querySnapshot.forEach(doc => {
      const docRef = doc.ref; // Reference to the current document
      const newCount = doc.data().CountOfOrders + prodqty;

      // Ensure CountOfOrder does not go below zero
      if (newCount >= 0) {
          batch.update(docRef, { CountOfOrders: newCount });
      } else {
          console.log(`CountOfOrder cannot go below zero for document: ${doc.id}`);
      }
  });
  await batch.commit();
  console.log('CountOfOrder Incremented successfully.');
    
  } catch (error) {
    console.error('Error Incremented CountOfOrder:', error);
  }

};


//Getting Service Provider at Reassign SP page
export const fetchServiceProviders = async (productId) => {
 
  console.log("Called Database Query")
   try {
       const q = query(collection(firestore, 'ProdSPMappingCollection'), where('ProdID', '==', productId));
       const snapshot = await getDocs(q);
       const providers = snapshot.docs.map(doc => ({
           id: doc.id,
           ...doc.data()
       }));
       
       return providers;
       
   } catch (error) {
       console.error("Error fetching service providers: ", error);
   } 
  
 };
 

//deletion function For EnquiryTransaction 
export const deleteRecordByEnqID = async (enqID)=>{
  const q = query(collection(firestore, "EnquiryTransaction"), where("enqID", "==", enqID));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.empty)
    {
      console.log(`No records found with enqID: ${enqID}`);
        return;
    }
    querySnapshot.forEach(async (docSnapshot) => {
        await deleteDoc(docSnapshot.ref);
        console.log(`Deleted record with ID: ${docSnapshot.id}`);
    });
} 