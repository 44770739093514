import PageFooter from "./PageFooter";
import { Box, Stack } from "@mui/material";
import BusProfPg1 from "../images/BusiProfPg1.png"
import BusProfPg2 from "../images/BusiProfPg2.png"
import NavBar from "../pages/OldNavBar";
import UserAlertMsg from "../components/UserAlertMsg";

import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NavBar2 from "../components/Navbar2";

export default function AboutUsPg() {

    const [open, setOpen] = React.useState(false);
    let alertMsg = "adfdfsas";

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            
            <NavBar2></NavBar2>

{/* 
            <Button variant="contained"
                onClick={handleClick}

            >
                Sample Button
            </Button> */}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}

                >
                    {/* This is a success Alert inside a Snackbar! */}
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Box
                display="flex"
                sx={{
                    alignContent: "center",
                    justifyContent: "center",
                    p: 10,

                }}
            >
                <Stack spacing={2}>
                    <img
                        // component="img"
                        width="50%"
                        height="50%"
                        // maxHeight="30 px"
                        border={"lightgreen"}
                        src={BusProfPg1}
                    ></img>

                    <img
                        // component="img"
                        width="50%"
                        height="50%"
                        // maxHeight="30 px"
                        border={"lightgreen"}
                        src={BusProfPg2}
                    ></img>

                </Stack>

            </Box>
            <PageFooter />
        </>
    );

}