// ProductPage.jsx
import React from "react";
import { Container, Paper, Typography, Grid, Stack, Breadcrumbs, Link } from "@mui/material";
import ProductDetails from "./productdetails";
import ProductImageGallery from "./productimages";
import NavBar from "./OldNavBar";
import Switch from "@mui/material/Switch";
import NavBar2 from "../components/Navbar2";

const ProductPage = () => {
  return (
    <>
      <NavBar2 cartDisplayProp={'show'}
              showMenuBarProp={'show'}
            />

      {/* adding breadcumbs to indicate the location as home/Product List/Product Details  */}
     {/* <Breadcrumbs sx={{m:3}}>
     
          
     <Link underline="hover" color="inherit" href="/home">
           Home
     </Link>

     <Link underline="hover" color="inherit" href="/filters">
           Product Listing
     </Link>

     <Typography color="text.primary">Product Details</Typography>
   
   </Breadcrumbs> */}


      <Container maxWidth="lg">
        <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
          <Typography variant="h4" gutterBottom>
            Product Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ProductImageGallery />
              {/* <Stack direction="column" my="10px">
                <item>
                  <Stack direction="row">
                    <Switch defaultChecked />
                    <Typography variant="h6" paragraph>
                      Installation Required
                    </Typography>
                  </Stack>
                </item>
                <item>
                  <Stack direction="row">
                    <Switch defaultChecked />
                    <Typography variant="h6" paragraph>
                      Old Battery return
                    </Typography>
                  </Stack>
                </item>
                <item>
                  <Stack direction="row">
                    <Switch defaultunChecked />
                    <Typography variant="h6" paragraph>
                      COD{" "}
                    </Typography>
                  </Stack>
                </item>
              </Stack> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <ProductDetails />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default ProductPage;
