import React from 'react'
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Box,Typography } from '@mui/material';
import { useState } from 'react';
import {Button} from '@mui/material';
import {
	setSessionDeliveryPinCode,
	getSessionDeliveryPinCode,
} from "../utils/sessionCheck";
import { getAllDeliveryPinCodes } from "../DatabaseAPI/DatabaseQuery";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect } from "react";
import { useRef } from 'react';
import { CartState } from "../context/ShopContext";

function PincodePopup(props) {

  const [open, setOpen] = React.useState(false);
  const [pincodes, setPincodes] = useState([]);
  const [selectedPinCode, setSelectedPinCode] = useState("");
	const [inputPincode, setInputPincode] = useState("");
	const [isPincodeAvailable, setIsPincodeAvailable] = useState(null);
  const [message, setMessage] = useState("");

  
  const { setCart } = CartState();

  const handleClickAway = () => {
    setOpen(false);
  };
  const modalRef = useRef();

  useEffect(() => {
    if (setOpen) {
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      props.setTrigger(false);
    }
  };

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    zIndex: 99999
  };
  const innerstyles={
    // position: 'relative',
    padding: '3px',
    width: '100%',
    height: '100%',
    // maxWidth: '450px',
    color: 'success.main',
    // bgcolor:'text.primary',
  }
  const closestyle={
    position: 'absolute',
    fontWeight: 'bold',
    color: '#dc2b2b',
    top: '16px',
    right: '16px'
  }
  useEffect(() => {
		
		let cd = (getSessionDeliveryPinCode);
    setSelectedPinCode(cd);
		console.log("Selected Pincode:", selectedPinCode);
	}, [selectedPinCode]);

  const extractPincodes = async () => {
		try {
			// Fetch all delivery pincodes from the database
			const tempDelveryPinList = await getAllDeliveryPinCodes();

			// Initialize an array to store the pincodes
			const pincodesArray = [];

			// Loop through each document and extract the pincode
			tempDelveryPinList.forEach((pinDoc) => {
				const tempPinDoc = JSON.parse(pinDoc).pincode;
				pincodesArray.push(tempPinDoc);
			});

			// console.log("Extracted Pincodes:", pincodesArray);

			return pincodesArray;
		} catch (err) {
			console.error("Error extracting pincodes:", err);
			return [];
		}
	};

  useEffect(() => {
		// Function to fetch and set pincodes
		const fetchPincodes = async () => {
			const availablePincodes = await extractPincodes();
			setPincodes(availablePincodes);
			// console.log("Available Pincodes:", availablePincodes);
		};

		fetchPincodes();
	}, []);

	const handleInputChange = (event) => {
		const { value } = event.target;
		// Allow only numbers and ensure the input is not more than 6 digits
		if (/^\d{0,6}$/.test(value)) {
			setInputPincode(value);
		}
	};

	const handlePincodeCheck = () => {
		// Check if the input pincode is available in the array
		// const available = pincodes.includes(inputPincode);// Convert inputPincode to integer for comparison
		const inputAsInt = parseInt(inputPincode, 10);
		// Check if the input pincode is available in the array
		const available = pincodes.includes(inputAsInt);
		setIsPincodeAvailable(available);
		// console.log("Pincodes:", pincodes);
		if (available) {
      props.setSelectedPinCode(Number(inputPincode));
      setSessionDeliveryPinCode(Number(inputPincode));
			setMessage("Pincode is available!");  
      console.log("selectedPinCode", Number(inputPincode));
      setCart([]);
      props.setTrigger(false);
      // const pin = JSON.parse(getSessionDeliveryPinCode());
      // console.log(pin);
		} else {
			setMessage("Pincode is not available.");
		}
	};

  return (props.trigger) ? (
    <ClickAwayListener onClickAway={handleClickAway}>
    <Box sx={styles}>
      <Box sx={innerstyles}>
      <Button sx={closestyle} onClick={()=> props.setTrigger(false)}>X</Button>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:'8px',fontWeight:'bold'}}>
            Change Pincode
          </Typography>        
          <input type="text" variant="filled" placeholder={selectedPinCode} onChange={handleInputChange} style={{ height:'30px', width:'60%', mt:'16px', borderRadius:'4px'}} />
        <Button onClick={handlePincodeCheck} variant="contained" maxlength="6" sx={{ml:'16px'}}>Submit</Button>
        <Typography sx = {{fontWeight:'light', fontSize:'13px',color:'black'}}> Note*: Changing the pincode will remove all the items from the cart </Typography>
        {message && (
            <Typography
              variant="body2"
              color={message.includes("available!") ? "green" : "red"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ml: 2,
                mt: 1,
              }}
            >
              {message.includes("available!") ? (
                <CheckCircleOutlineIcon sx={{ mr: 1, height: "20px" }} />
              ) : (
                <ErrorOutlineIcon sx={{ mr: 1, height: "20px" }} />
              )}
              {message}
            </Typography>
        )}
      </Box>
    </Box>
    </ClickAwayListener>
  ) : "";
}

export default PincodePopup;
