import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Button, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import NavBar from './OldNavBar';
import PageFooter from '../footer/PageFooter';
import NavBar2 from '../components/Navbar2';

const firestore = getFirestore();
export default function ThankYouPage() {
  const location = useLocation();
  const [orderData, setOrderData] = useState(null);

  const fetchOrderData = async (orderReferenceID) => {
    try {
      const orderDocRef = doc(firestore, 'OrderMaster', orderReferenceID);
      const orderSnapshot = await getDoc(orderDocRef);
      if (orderSnapshot.exists()) {
        setOrderData(orderSnapshot.data());
      } else {
        console.error('Order not found');
      }
    } catch (error) {
      console.error('Error fetching order data:', error);
    }
  };

  useEffect(() => {
    try{

        if (location.state?.orderReferenceID) {
          fetchOrderData(location.state.orderReferenceID);
      }
    } catch(e)
    {
      console.log("Order Reference is not available", e);
    }
  }, [location.state?.orderReferenceID]);

  return (
    <Box>
      <NavBar2 cartDisplayProp={'none'} showMenuBarProp={'show'} />
      <Container sx={{ m: 5 }}>
        <Typography variant="h3" gutterBottom>
          Thank You for shopping with us !!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your Order reference Number is: <b>{location.state.orderReferenceID}</b>
        </Typography>
        <Typography variant="body1" gutterBottom>
          We've received your order and will process it shortly.
        </Typography>

        {orderData && (
          <TableContainer component={Paper} sx={{ marginTop: 4 }}>
            <Table aria-label="order details table">
              <TableHead>
                <TableRow>
                  <TableCell><b>Field</b></TableCell>
                  <TableCell><b>Value</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(orderData).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value.toString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Button variant="contained" color="success" sx={{ mt: 5 }} component={Link} to="/home">
          Continue Shopping with us
        </Button>
      </Container>
      <PageFooter />
    </Box>
  );
}
