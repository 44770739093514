//Importanat File for Keeping Shopping cart 
import { createContext, useContext, useState } from "react";
// import { ALLPRODUCTLIST } from "../pages/ProdCardListPg";
// import { ALLPRODUCTLIST } from "../components/cardtrial";
// import { setLast } from "../utils/sessionCheck";
import { useEffect } from "react";

const Cart = createContext();

const ShopContext = ({ children }) => {
  const [cart, setCart] = useState([]);
  // console.log(ALLPRODUCTLIST);
  // const productsArray = ALLPRODUCTLIST;
  const [prodDetailID, setProdDetailID] = useState("");

  const [products, setProducts] = useState([]);
  const updateProducts = (newProducts) => {
    setProducts(newProducts);
    // console.log(newProducts.length);
    // console.log(newProducts);

    // if (products.length > 0) {
    //   console.log(Math.max(...products.map((product) => product.index)));
    //   setLast(Math.max(...products.map((product) => product.index)));
    // }
    // updateLast();
  };

  // const updateLast = () => {
  //   if (products.length > 0) {
  //     console.log(Math.max(...products.map((product) => product.index)));
  //     setLast(Math.max(...products.map((product) => product.index)));
  //   }
  // };

  return (
    <Cart.Provider
      value={{
        cart,
        updateProducts,
        setCart,
        products,
        prodDetailID,
        setProdDetailID,
      }}
    >
      {children}
    </Cart.Provider>
  );
};

export const CartState = () => {
  return useContext(Cart);
};

export default ShopContext;
