import { Box, Typography, getToggleButtonUtilityClass, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function PrivyPolicy() {

    return (
        <>

            <Box sx={{ p: 5 }}>
                <p>
                    Privacy Policy:<br/>
                    By using ReUrja.com website and services you agree to our use of your personal information (including sensitive personal information) in accordance with this Privacy Notice, as may be amended from time to time by us at our discretion. You also agree and consent to us collecting, storing, processing, transferring, and sharing your personal information (including sensitive personal information) with third parties or service providers for the purposes set out in this Privacy Notice. <br />
                    We may automatically collect and store certain types of information about your use of ReUrja.com and it’s services, including information about your interaction with content and services available through ReUrja.com. We may use cookies and other unique identifiers, and we obtain certain types of information when your web browser or device accesses ReUrja.com and it’s services and other content served by or on behalf of ReUrja.com on other websites. <br />
                    We might receive information about you from other sources, such as updated delivery and address information from our carriers, which we use to correct our records and deliver your next purchase more easily. <br />
                    We use your personal information to operate, provide, develop, and improve the products and services that we offer our customers. These purposes include: <br />
                    •	We may use your personal information to take and fulfill orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers. <br />
                    •	We may use your personal information to provide functionality, analyze performance, fix errors, and improve the usability and effectiveness of the ReUrja.com Services. <br />
                    •	We may use your personal information to recommend features, products, and services that might be of interest to you, identify your preferences, and personalize your experience with ReUrja.com / Services. <br />
                    •	In certain cases, we may collect and use your personal information to comply with laws. <br />
                    •	We may use your personal information to communicate with you in relation to ReUrja.com and it’s associated product(s) and/or services via different channels (e.g., by phone, e-mail, chat). <br />
                    •	 We may use your personal information to display interest-based ads for features, products, and services that might be of interest to you. <br />
                    •	We may use personal information to prevent and detect fraud and abuse in order to protect the security of our customers, ReUrja.com, and others. <br />
                    To enable our systems to recognize your browser or device and to provide and improve ReUrja.com Services, we use cookies and other identifiers. <br />

                    Third-Party Advertisers and Links to Other Websites: ReUrja.com may include third-party advertising and links to other websites and apps. Third-party advertising partners may collect information about you when you interact with their content, advertising, and services. <br />


                </p>
                <p>
                    <Link to='/3rdprtypolicy'>Third Party Libraries</Link>
                </p>
            </Box>


        </>
    );

}