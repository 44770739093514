import {
	Grid,
	Paper,
	Avatar,
	Button,
	Typography,
	Stack,
	FormControl,
	Alert,
	AlertTitle,
	Snackbar,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import LockIcon from "@mui/icons-material/Lock";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
	setUserEmail,
	setIsAdmin,
	setIsOps,
	setSessionUserPhoneNumber,
	setSessionDeliveryPinCode,
	setSessionSelectPinCodeAllData,
	setUserDisplayName,
	getSessionDeliveryPinCode,
} from "../utils/sessionCheck";
import { auth, provider, firestore } from "../firebase.config";
import { signInWithPopup } from "firebase/auth";
import Divider from "@mui/material/Divider";
import NavBar from "./OldNavBar";
import { useState } from "react";
import GoogleIcon from "@mui/icons-material/Google";
import { collection, getDocs, query, where } from "firebase/firestore";
import { TextField } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { setUserPincode } from "../utils/sessionCheck";
import { clearSessionDeliveryPinCode } from "../utils/sessionCheck";
import NavBar2 from "../components/Navbar2";
import { getUserProfile } from "../DatabaseAPI/DatabaseQuery";
import { useEffect } from "react";

const paperstyle = {
	padding: 20,
	height: "45vh",
	width: 460,
	margin: "20px auto",
};

//array that handels key and value types of the value selected form home page without login
export const FORM_TYPES = {
	ON_GRID: "On Grid Solar",
	GREEN_OPEN_ACCESS: "Green Open Access",
	OFF_GRID: "Off Grid Solar",
	SOLAR_PROJ_MGMT: "Solar Plant Project Management",

	BATTERY_AMC: "AMC for Battery",
	SOLAR_AMC: "AMC for Solar Plant",
	DG_SET_AMC: "AMC for Diesel Generator Set",
	CCTV_AMC: "AMC for CCTV System",

	LED: "Bulk LED Requirements",
	CO2_CREDIT: "Carbon Credit Requirements",
	AUTOMATION: "Automation Requirements",

	ENERGY_AUDIT: "Energy Audit",
	GREEN_ENERGY_CONSULT: "Green Energy Consultancy",
	TRAININGS: "Training Requirements",
	CONTACT_US: "Contact Us",
};

// START login function that allow user to login with gmail id if valid exits and also in database then only user can login or user need to sign up first if not exits
const NewLogIn = () => {
	const navigate = useNavigate();
	const [human, setHuman] = useState(false);
	const [selectedPinCode, setSelectedPinCode] = useState("");
	const [inputPincode, setInputPincode] = useState("");
	const [message, setMessage] = useState("");
	const [validuser,setValiduser] = useState(false);
	const [countdown, setCountdown] = useState(5);

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("");

	//Effect to efficient redirect option 
	useEffect(() => {
        if (validuser) {
            const timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        navigate('/custsignup');
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [validuser, navigate]);

	const sitekey1 = process.env.REACT_APP_RECAPCTHA_SITE_KEY;

	const location = useLocation();
	console.log("Location is >>>>",location);

	const getPincodeDetails = async (pin) => {
		const tempPinCdArray = [];
		// console.log("Pincode: " + selectedPinCode)
		try {
			const pincodeCollection = collection(firestore, "PinCode_Master");
			const userpincode = parseInt(pin, 10);
			const selectedPinCode = userpincode;
			const q = query(pincodeCollection, where("pinCodes", "==", userpincode));
			const querySnapshot = await getDocs(q);
			console.log("Fetching data for pincode:", selectedPinCode);
			console.log("Number of documents found: ", querySnapshot.size);
			querySnapshot.forEach((doc) => {
				const pinCodeRecord = {
					pincode: doc.data().pinCodes,
					successPartnerName: doc.data().successPartnerName,
					successPartnerAddress: doc.data().successPartnerAddress,
					successPartnerEmailID1: doc.data().successPartnerEmailID1,
					successPartnerEmailID2: doc.data().successPartnerEmailID2,
					successPartnerMobileNo: doc.data().successPartnerMobileNo,
					successPartnerSPGrpID: doc.data().spGrpID,
				};
				tempPinCdArray.push(JSON.stringify(pinCodeRecord));
			});

			// Store the fetched data in the session or any state management
			setSessionSelectPinCodeAllData(tempPinCdArray);
			// console.log("Session Pincode Data:",cd)

			//console.log("All data fetched for the selected pincode:", tempPinCdArray);
		} catch (error) {
			console.error("Error fetching pincode details:", error);
		}
	};
	// function to initiate use login with Google

	// const FORM_TYPES = {
	// 	ON_GRID: "On Grid Solar",
	// 	GREEN_OPEN_ACCESS: "Green Open Access",
	// 	OFF_GRID: "Off Grid Solar",
	// 	SOLAR_PROJ_MGMT: "Solar Plant Project Management",

	// 	BATTERY_AMC: "AMC for Battery",
	// 	SOLAR_AMC: "AMC for Solar Plant",
	// 	DG_SET_AMC: "AMC for Diesel Generator Set",
	// 	CCTV_AMC: "AMC for CCTV System",

	// 	LED: "Bulk LED Requirements",
	// 	CO2_CREDIT: "Carbon Credit Requirements",
	// 	AUTOMATION: "Automation Requirements",

	// 	ENERGY_AUDIT: "Energy Audit",
	// 	GREEN_ENERGY_CONSULT: "Green Energy Consultancy",
	// 	TRAININGS: "Training Requirements",
	// 	CONTACT_US: "Contact Us",
	// };

	const logGoogleUser = () => {
		try {
			const check = location.state?.t; //safty check for state empty or null;
			console.log(check);
			signInWithPopup(auth, provider)
				.then(async (data) => {
					const userEmail = data.user.email;
					console.log(userEmail);
					const userdata = await getUserProfile(userEmail);

					const pin = userdata[0];

					if (pin) {
						setSessionDeliveryPinCode(pin);
						setUserEmail(userEmail);
						setUserDisplayName(data.user.displayName);
						setSessionUserPhoneNumber(data.user.phoneNumber);
						getPincodeDetails(pin);

						const isAdmin = [
							process.env.REACT_APP_ADMIN1,
							process.env.REACT_APP_ADMIN2,
							process.env.REACT_APP_ADMIN3,
						].includes(data.user.email);
						if (isAdmin) {
							// console.log(data.user.email + " is admin");
							setIsAdmin(true);
							navigate("/filters");
						}
						const isOps = [
							process.env.REACT_APP_OPS1,
							process.env.REACT_APP_OPS2,
							process.env.REACT_APP_OPS3,
						].includes(data.user.email);
						if (isOps) {
							// console.log(data.user.email + " is Opearation person");
							setIsOps(true);
							navigate("/filters");
						}

						if (check === undefined) {
							navigate("/filters");
						} else if (
							check === FORM_TYPES.ON_GRID ||
							check === FORM_TYPES.OFF_GRID ||
							check === FORM_TYPES.GREEN_OPEN_ACCESS ||
							check === FORM_TYPES.SOLAR_PROJ_MGMT ||
							
							check === FORM_TYPES.BATTERY_AMC ||
							check === FORM_TYPES.SOLAR_AMC ||
							check === FORM_TYPES.DG_SET_AMC ||
							check === FORM_TYPES.CCTV_AMC ||

							check === FORM_TYPES.LED ||
							check === FORM_TYPES.CO2_CREDIT ||
							check === FORM_TYPES.AUTOMATION ||

							check === FORM_TYPES.ENERGY_AUDIT ||
							check === FORM_TYPES.GREEN_ENERGY_CONSULT ||
							check === FORM_TYPES.TRAININGS ||

							check === FORM_TYPES.CONTACT_US
						) {
							navigate("/enq", { state: { formType: check } });
						} else {
							console.log("User profile not found.");
						}
					} else {
						console.log("user is not signed in");
						setValiduser(true);
					}
				})
				.catch((error) => {
					console.error("Poppup Unexpectedly Closed: ", error);
				});
		} catch (error) {
			console.error("Error in finding the user: ", error);
			navigate("/");
		}
	};
//END 
	async function checkPincode() {
		const userpincode = parseInt(inputPincode, 10);
		if (isNaN(userpincode) || inputPincode.length !== 6) {
			alert("Please enter a valid 6-digit Pincode");
			return;
		}
		const pincodeCollection = collection(firestore, "PinCode_Master");
		const q = query(pincodeCollection, where("pinCodes", "==", userpincode));
		const querySnapshot = await getDocs(q);
		// console.log("Query Snap: " + querySnapshot.size);
		if (!querySnapshot.empty) {
			// const temparr2 = JSON.parse(temparr);
			// setSessionSelectPinCodeAllData(temparr);
			const doc = querySnapshot.docs[0];
			const pinCode = doc.data().pinCodes;
			setUserPincode(pinCode);
			// console.log("Pincode found:", pinCode);
			setSelectedPinCode(pinCode);
			// getPincodeDetails(inputPincode);
			let pincode = Number(pinCode);
			setSessionDeliveryPinCode(pincode);
			// console.log("Delivery Pincode:", pincode);
			setMessage("Pincode is available!");
			return doc.data();
		} else {
			console.log("Pincode not found");
			setMessage("Pincode is not available.");
			return null;
		}
	}

	const handleInputChange = (event) => {
		const { value } = event.target;
		if (/^\d{0,6}$/.test(value)) {
			setInputPincode(value);
		}
	};

	function onChange(value) {
		if (value !== "NaN") {
			setHuman("True");
		}
	}

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
	return (
		<>
			{/* <NavBar cartDisplayProp={"none"} showMenuBarProp={"show"} /> */}

			<NavBar2 cartDisplayProp={"none"} showMenuBarProp={"show"} />
			{/* <Divider variant="middle" sx={{ p: 1}}></Divider> */}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				variant="filled"
				severity={snackbarSeverity}
				onClose={handleSnackbarClose}
				message={snackbarMessage}
			>
				 <Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					variant="filled"
					sx={{ width: "100%", color: 'black' }}
       			 >
          
					{snackbarMessage}
				</Alert>
			</Snackbar>
			
			{
				validuser ? 
				<Alert severity="error">
				<AlertTitle><strong>Error</strong></AlertTitle>
					You Are New To Site Please First Signup Redirecting in <strong>{countdown} seconds...</strong>
				</Alert> : null
			}

			<Grid
				container
				justifyContent="center"
				alignContent={"center"}
				sx={{ height: "100vh" }}
			>
				<Box sx={{border: 1, p:3,
					background: 'linear-gradient(to right bottom, #61ff33, #ffe333)'
				}}>
					
					<Stack
						direction="column"
						spacing={2}
						alignItems="center"
						height={"60vh"}
					>
						{/* START - Code for selecting a delivery PinCode */}
						<Typography variant="h6" sx={{ color: "blue" }}>
							<b>Login </b>
						</Typography>
						<Box minWidth="50%" display="flex">
							<FormControl fullWidth>
								{message && (
									<Typography
										variant="body2"
										color={message.includes("available!") ? "green" : "red"}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											ml: 2,
											mt: 1,
											position: "relative",
											top: 12,
										}}
									>
										{message.includes("available!") ? (
											<CheckCircleOutlineIcon sx={{ mr: 1, height: "20px" }} />
										) : (
											<ErrorOutlineIcon sx={{ mr: 1, height: "20px" }} />
										)}
										{message}
									</Typography>
								)}
							</FormControl>
						</Box>

						<Avatar sx={{ bgcolor: "green" }}>
							<LockIcon />
						</Avatar>
						<ReCAPTCHA sitekey={sitekey1} onChange={onChange} />
						<Button
							type="button"
							startIcon={<GoogleIcon />}
							sx={{ bgcolor: "green", width: "85%", m: 4 }}
							variant="contained"
							fullWidth
							onClick={() => {
								try {
									//if (getSessionDeliveryPinCode())
									if (human === "True") {
										logGoogleUser();
									} else if (human !== "True") {
										setSnackbarMessage("Please verify that you are human");
										setSnackbarSeverity("warning");
										setSnackbarOpen(true);
										return;
									} else {
										return alert("Please select a Delivery Pin code");
									}
								} catch (error) {
									clearSessionDeliveryPinCode();
									// console.log('User did not log in', error);
								}
							}}
						>
							Log In With Google
						</Button>
						{/* 
						<Button variant="text"
				onClick={()=>{
					navigate("/custsignup")
				}
				}
					> */}
						<Link to={"/custsignup"}>
							<b> New Users - SIGNUP here </b>
						</Link>

						{/* </Button> */}
					</Stack>
				{/* </Paper> */}
				</Box>
			</Grid>
			
		</>
	);
};

export default NewLogIn;
