import React, { useState } from 'react';
import {Typography,Button,MenuItem,TextField, Paper} from "@mui/material";
import NavBar from './OldNavBar';
import PageFooter from '../footer/PageFooter';
import NavBar2 from '../components/Navbar2';

const cities = [
  { value: 'Mumbai', label: 'Mumbai' },
  { value: 'Pune', label: 'Pune' },
  { value: 'Nagpur', label: 'Nagpur' },
  // Add more cities as needed
];

const showrooms = [
  { value: 'Shivajinagar', label: 'Shivajinagar' },
  { value: 'Kothrud', label: 'Kothrud' },
  // Add more showrooms as needed
];

const paperstyle = {
    padding: 20,
    height: "auto",
    width: 460,
    margin: "20px auto",
    border: "2px solid #07b307",
    align: "middle"
  };

const EVEnquiryForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [bookingDate, setBookingDate] = useState('');
  const [bookingTime, setBookingTime] = useState('');
  const [showroom, setShowroom] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send form data to server or perform other actions here
    console.log({ name, email, phone, city, bookingDate, bookingTime, showroom });
    // You can add code here to send data to a backend or perform other actions
  };

  return (
    <>
    <NavBar2></NavBar2>
    
    <form style={{ maxWidth: '400px', margin: 'auto' }} onSubmit={handleSubmit}>
        <Paper style={paperstyle}>
        <Typography variant="h3" gutterBottom>
        Book a test ride
      </Typography>
      <TextField
        label="Name"
        variant="outlined"
        style={{ marginBottom: '16px' }}
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        fullWidth
      />
      <TextField
        label="Email"
        variant="outlined"
        style={{ marginBottom: '16px' }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        required
        fullWidth
      />
      <TextField
        label="Phone"
        variant="outlined"
        style={{ marginBottom: '16px' }}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        type="tel"
        required
        fullWidth
      />
      <TextField
        select
        label="City"
        variant="outlined"
        style={{ marginBottom: '16px' }}
        value={city}
        onChange={(e) => setCity(e.target.value)}
        required
        fullWidth
      >
        {cities.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Preferred Date"
        variant="outlined"
        style={{ marginBottom: '16px', width:200, marginRight:"20px", marginLeft:"20px"}}
        value={bookingDate}
        onChange={(e) => setBookingDate(e.target.value)}
        type="date"
        required
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="Preferred Time"
        variant="outlined"
        style={{ marginBottom: '16px' ,width:200}}
        value={bookingTime}
        onChange={(e) => setBookingTime(e.target.value)}
        type="time"
        required
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
      <TextField
        select
        label="Showroom"
        variant="outlined"
        style={{ marginBottom: '16px' }}
        value={showroom}
        onChange={(e) => setShowroom(e.target.value)}
        required
        fullWidth
      >
        {showrooms.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="contained"
        color="success"
        type="submit"
      >
        Book
      </Button>
      </Paper>
    </form>
    <PageFooter/>
    </>
  );
};

export default EVEnquiryForm;
