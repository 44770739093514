import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Breadcrumbs, Button, Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import Drawers from "./Drawers";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ProdCardListPg from "./ProdCardListPg";
import { CartState } from "../context/ShopContext";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

import NavBar from "../components/Navbar2";
import {
	GridCards,
	GridCardExpand,
	LoadMoreCards,
	FilteredCards,
} from "../DatabaseAPI/DatabaseQuery";
import PageFooter from "../footer/PageFooter";
import { Tune } from "@mui/icons-material";
import { getLast, getSessionProdListByType } from "../utils/sessionCheck";
import NavBar2 from "../components/Navbar2";
import { getUserEmail } from "../utils/sessionCheck";
import Carousel2 from "../components/Carousel2";
// import { useLocation } from "react-router-dom";

export default function Filters() {
	const [openDrawer, setOpenDrawer] = useState(false);
	const last = parseInt(getLast());
	// const location = useLocation();
	
	//acces cart details
	const { cart, setCart, products, updateProducts } = CartState();

	const navigate = useNavigate();

	const handleDrawerClick = () => {
		setOpenDrawer(!openDrawer);
	};

	

	
	let type = getSessionProdListByType();
	const tupe = async() =>
	{
		try {
			const productList = await FilteredCards(
			  "",
			  type,
			  "",
			  "",
			  ""
			  
			);
			
			updateProducts(productList);
		  } catch (error) {
			console.error("Error fetching data:", error);
		  }
	}

	useEffect(() => {
		if(getUserEmail() === null){
			navigate('/login')
		}
		else if(getSessionProdListByType()){
		tupe();
		}
		
		console.log(getSessionProdListByType());
	},[])

	

	

	const fetchData = async () => {
		try {
			// const newCards = await GridCardExpand(
			//   2,
			//   5,
			//   products[0].prodProductCategory
			// );
			const newCards = await LoadMoreCards();
			updateProducts((prevCards) => [...prevCards, ...newCards]);
		} catch (error) {
			console.log(error);
		}
	};

	

	return (
		<>
		{getUserEmail() ?(

			<Box>
				<NavBar2 cartDisplayProp={"show"} showMenuBarProp={"show"} />
				<Carousel2 />

				{/* adding breadcumbs to indicate the location as home/Product List */}
				{/* <Breadcrumbs sx={{ m: 3 }}>
					<Link underline="hover" color="inherit" href="/home">
						Home
					</Link>

					<Typography color="text.primary">Product Listing</Typography>
				</Breadcrumbs> */}
				{/* <Button onClick={() => setButtonPopup(true)}> Popup </Button> */}
				<Typography
					variant="h4"
					component="div"
					sx={{ flexGrow: 1, textAlign: "center", m: 4 }}
				>
					Search Results
				</Typography>
				
				<Box
					height={1370}
					// width={1370}
					maxwidth={1370}
					display={"flex"}
					sx={{ m: 3 }}
					// sx={{ border: "2px solid #91bbff", m: 3 }}
				>
					<Box display={"block"}>
						{" "}
						<Typography variant="body" sx={{ color: "success" }}>
							<b>Filters</b>
						</Typography>
						<Tooltip title="Filter">
							<Button
								variant="contained"
								//color="inherit"
								color="success"
								// backgroundColor='
								onClick={handleDrawerClick}
								// startIcon={<FilterAltIcon />}
								startIcon={<Tune />}
								sx={{ ml: 2 }}
								>
								{/* Filters */}
							</Button>
						</Tooltip>
						{openDrawer ? <Drawers /> : <></>}
					</Box>
					<Box
						// height={1370}
						// width={1120}
						sx={{ m: 1 }}
						// sx={{ border: "2px solid #91bbff", m: 3 }}
						>
						<ProdCardListPg />
						{/* {isLoading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>} */}
						{/* <Box display={"flex"} justifyContent={"center"}>
              <Button
							variant="contained"
							sx={{ px: 50, py: 2, m: 6, bgcolor: "#25d911",
                  xs: 2, sm: 6, md: 12}}
                onClick={fetchData}
              >
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Load More
                </Typography>
              </Button>
            </Box> */}
						{products.length != 0 && last != 0 ? (
							<Box>
								<Button
									variant="contained"
									sx={{
										color: "success",
										ml: 5,
										mb: 2,
										maxWidth: "80%",
										px: "30%",
									}}
									onClick={fetchData}
									>
									<b>Load&nbsp;More... </b>
								</Button>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</Box>
				{/* <PageFooter/> */}
			</Box>
		):(
			navigate('/login')
		)
	}
		</>
	);
}
