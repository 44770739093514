import PageFooter from "./PageFooter";
import { Box, Typography, getToggleButtonUtilityClass, Button } from "@mui/material";

export default function ThirdPrtyPolicy() {

    return (
        <>

            <Box sx={{ p: 5 }}>
                <p>
                    Use and declarations related to Third party software Libraries and Software: <br />
                    To enable a seamless browsing experience to our customers, we rely on using the third party libraries and software. These third party libraries and software are governed by their own usage policies, declarations and privacy policies. Your use of ReUrja.com website automatically mandates you to follow the terms and conditions, governing rules / regulations, declarations, privacy policies etc. applicable under these Third party libraries and software. You may find the details of these at the below listed links. <br />
                    Third party software libraries and/or components Terms, Privacy policies,
                    license references: <br />
                    •	https://mui.com/ , https://mui.com/legal/,
                    •	https://github.com/remix-run/react-router, https://github.com/remix-run/react-router?tab=MIT-1-ov-file#readme,
                    •	https://github.com/dozoisch/react-google-recaptcha?tab=MIT-1-ov-file#readme,
                    •	https://www.svgrepo.com/page/licensing/,<br />
                    •	React - https://github.com/facebook/react/blob/main/LICENSE
                    •	Xlsx - https://github.com/SheetJS/sheetjs?tab=Apache-2.0-1-ov-file#readme,
                    •	React router dom - https://github.com/remix-run/react-router?tab=MIT-1-ov-file#readme,
                    •	Uuid - https://github.com/uuidjs/uuid?tab=MIT-1-ov-file#readme,
                    •	Nanoid - https://github.com/ai/nanoid?tab=MIT-1-ov-file#readme,
                    

                </p>
            </Box>


        </>
    );

}