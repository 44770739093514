// Logged in user email details stored in session
export const setUserEmail = (email) => {
  // const email2 = JSON.stringify(email)
  sessionStorage.setItem("userEmail", email);
};


export const getUserEmail = () => {
  const userEmail = sessionStorage.getItem("userEmail");
  // console.log("userEmail:",userEmail);
  return sessionStorage.getItem("userEmail");
};

export const setUserDisplayName = (displayName) => {
  sessionStorage.setItem("userDisplayName", displayName);
};

export const getUserDisplayName = () => {
  return sessionStorage.getItem("userDisplayName");
};
export const cearUserDisplayName = () => {
  return sessionStorage.removeItem("userDisplayName");
};

export const setUserPincode = (pincode) => {
  const pincode2 = Number(pincode)
  sessionStorage.setItem("userPincode", pincode2);
};

export const getUserPincode = () => {
  let cd = Number(sessionStorage.getItem("userPincode"));
  return cd; 
};

export const clearUserEmail = () => {
  sessionStorage.removeItem("userEmail");
};

// Logged in users phone number stored in session
export const setSessionUserPhoneNumber = (uPhoneNumber) => {
// if user phone number is not available after Google signup then assign NO Value to phoneNumber
  if(uPhoneNumber === null)
    uPhoneNumber = "";
  sessionStorage.setItem("userPhoneNumber", uPhoneNumber);
};

export const getSessionUserPhoneNumber = () => {
  return sessionStorage.getItem("userPhoneNumber");
};

export const clearSessionUserPhoneNumber = () => {
  sessionStorage.removeItem("userPhoneNumber");
};

export const setIsSPOps = (IsSPOps) => {
  sessionStorage.setItem("IsSPOps", IsSPOps);
};

export const getIsSPOps = () => {
  return sessionStorage.getItem("IsSPOps");
};

export const clearIsSPOps = () => {
  sessionStorage.removeItem("IsSPOps");
};

export const setSPUserGrpID = (spGrpID) => {
  sessionStorage.setItem("spGrpID", spGrpID);
};

export const getSPUserGrpID = () => {
  return sessionStorage.getItem("spGrpID");
};

export const clearSPUserGrpID = () => {
  sessionStorage.removeItem("spGrpID");
};

export const setIsOps = (IsOps) => {
  sessionStorage.setItem("IsOps", IsOps);
};

export const getIsOps = () => {
  return sessionStorage.getItem("IsOps");
};

export const clearIsOps = () => {
  sessionStorage.removeItem("IsOps");
};

export const setIsAdmin = (IsAdmin) => {
  sessionStorage.setItem("IsAdmin", IsAdmin);
};

export const getIsAdmin = () => {
  return sessionStorage.getItem("IsAdmin");
};

export const clearIsAdmin = () => {
  sessionStorage.removeItem("IsAdmin");
};

export const setLast = (Last) => {
  // sessionStorage.setItem("Last", JSON.stringify(Last));
  sessionStorage.setItem("Last", Last);
};

export const getLast = () => {
  return sessionStorage.getItem("Last");
  // const lastVisibleString = sessionStorage.getItem("Last");
  // return lastVisibleString ? JSON.parse(lastVisibleString) : null;
};

export const clearLast = () => {
  sessionStorage.removeItem("Last");
};

export const setLastOrder = (Order) => {
  // sessionStorage.setItem("Last", JSON.stringify(Last));
  sessionStorage.setItem("Order", Order);
};
export const setLastEnquiry = (Enquiry) => {
  // sessionStorage.setItem("Last", JSON.stringify(Last));
  sessionStorage.setItem("Enquiry", Enquiry);
};

export const getLastOrder = () => {
  return sessionStorage.getItem("Order");
  // const lastVisibleString = sessionStorage.getItem("Last");
  // return lastVisibleString ? JSON.parse(lastVisibleString) : null;
};

export const getLastEnquiry = () => {
  return sessionStorage.getItem("Enquiry");
  // const lastVisibleString = sessionStorage.getItem("Last");
  // return lastVisibleString ? JSON.parse(lastVisibleString) : null;
};

export const clearLastOrder = () => {
  sessionStorage.removeItem("Order");
};

export const setLoadMore = (Load) => {
  sessionStorage.setItem("Load", JSON.stringify(Load));
  // sessionStorage.setItem("Load", Load);
};

export const getLoadMore = () => {
  return sessionStorage.getItem("Load");
  // const lastVisibleString = sessionStorage.getItem("Last");
  // return lastVisibleString ? JSON.parse(lastVisibleString) : null;
};

export const clearLoadMore = () => {
  sessionStorage.removeItem("Load");
};

// export const setSignInTime = (time) => {
//   sessionStorage.setItem("SignInTime", time);
// };

// export const getSignInTime = () => {
//   return sessionStorage.getItem("SignInTime");
// };

// export const clearSignInTime = () => {
//   sessionStorage.removeItem("SignInTime");
// };


// START - Set Delivery Pincode
export const setSessionDeliveryPinCode = (pinCd) => {
  let cd = Number(pinCd)
  sessionStorage.setItem("delivryPinCode", cd);
  // console.log("setSessionDeliveryPinCode >> ", sessionStorage.getItem("delivryPinCode"));
};

export const getSessionDeliveryPinCode = () => {
  // console.log("Delivery pincode is >> ", sessionStorage.getItem("delivryPinCode"));
  let cd = Number(sessionStorage.getItem("delivryPinCode"));
  return cd;
};

export const clearSessionDeliveryPinCode = () => {
  sessionStorage.removeItem("delivryPinCode");
};


// START - Set slected Pincode's GRP ID and success partner details
export const setSessionSelectPinCodeAllData = (pinCdDataJsonStr) => {
  sessionStorage.setItem("pinCodeDataJsonStr", pinCdDataJsonStr);
};

export const getSessionSelectPinCodeAllData = () => {
  // console.log("Delivery pincode is >> ", sessionStorage.getItem("delivryPinCode"));
  return sessionStorage.getItem("pinCodeDataJsonStr");
};

export const clearSessionSelectPinCodeAllData = () => {
  sessionStorage.removeItem("pinCodeDataJsonStr");
};

// START - Set slected Pincode's GRP ID and success partner details
export const setSessionSPOpsGrpID = (spGrpID) => {
  sessionStorage.setItem("spOpsGrpID", spGrpID);
};

export const getSessionSPOpsGrpID = () => {
  return sessionStorage.getItem("spOpsGrpID");
};

export const clearSessionSPOpsGrpID = () => {
  sessionStorage.removeItem("spOpsGrpID");
};

// START - Create a new Product list on Filter based on the Product Type from Home
export const setSessionProdListByType = (vProdListByType) => {
  sessionStorage.setItem("prodListByType", vProdListByType);
};

export const getSessionProdListByType = () => {
  // console.log("Delivery pincode is >> ", sessionStorage.getItem("delivryPinCode"));
  return sessionStorage.getItem("prodListByType");
};


export const clearSessionProdListByType = () => {
  sessionStorage.removeItem("prodListByType");
};


// START - Create a new Product list on Filter based on the Product Category from Home
export const setSessionProdListByCategory = (vProdListByCategory) => {
  sessionStorage.setItem("prodListByCategory", vProdListByCategory);
};

export const getSessionProdListByCategory = () => {
  // console.log("Delivery pincode is >> ", sessionStorage.getItem("delivryPinCode"));
  return sessionStorage.getItem("prodListByCategory");
};

export const clearSessionProdListByCategory = () => {
  sessionStorage.removeItem("prodListByCategory");
};


//###### START - Clear all the session object upon Logout
export const clearAllSessionObjects = () => {
  sessionStorage.removeItem("delivryPinCode");
  sessionStorage.removeItem("Load");
  sessionStorage.removeItem("Order");
  sessionStorage.removeItem("Last");
  sessionStorage.removeItem("IsAdmin");
  sessionStorage.removeItem("IsOps");
  sessionStorage.removeItem("IsSPOps");
  sessionStorage.removeItem("userPhoneNumber");
  sessionStorage.removeItem("userEmail");
  sessionStorage.removeItem("spGrpID");
  sessionStorage.removeItem("pinCodeDataJsonStr");
  sessionStorage.removeItem("spOpsGrpID");
  sessionStorage.removeItem("userDisplayName");
  sessionStorage.removeItem("prodListByType");
  sessionStorage.removeItem("prodListByCategory");

  console.log("<<<< Cleared all session objects >>>");
};

//###### END - Clear all the session object upon Logout

// export const setSessionSuccessPartnerDetails= (successPrtnrDtls) =>{
//   sessionStorage.setItem("successPartnerDetials", successPrtnrDtls);
// }

// export const getSessionSuccessPartnerDetails= () =>{
//   return sessionStorage.getItem("successPartnerDetials");
// }

// export const clearSessionSuccessPartnerDetails= (successPrtnrDtls) =>{
//   sessionStorage.removeItem("successPartnerDetials");
// }
// END - Set Delivery Pincode