import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import HomePgComp from "../components/HomePgComp";
import NavBar from "./OldNavBar";
import { Divider, Slider } from "@mui/material";
import PageFooter from "../footer/PageFooter";
import BreadCrumbs from "../components/breadCrumbs";
import NavBar2 from "../components/Navbar2";
import NewHomePgComp2 from "../components/NewHomePgComp2";
import Carousel2 from "../components/Carousel2";
import { useState } from "react";
import { useEffect } from "react";
import { storage } from '../firebase.config';
import { getDownloadURL, listAll, ref } from 'firebase/storage';

export default function Home() {


  return (
    <>
    <Box>
      <NavBar2 cartDisplayProp={'none'}
              showMenuBarProp={'show'}
            />
    
    <Carousel2/>
     
{/*       
      <br/> */}
      {/* <Divider sx={{bgcolor:"green", borderBottomWidth: '2px'}}></Divider> */}
      {/* Paper and cards displayed on Home page are part of below component */}
      {/* <HomePgComp /> */}
      <NewHomePgComp2 />
      {/* <BreadCrumbs/> */}
    </Box>
    {/* <br/>
    <Divider sx={{bgcolor:"green", borderBottomWidth: '10px'}}></Divider> */}
    <br/>
    <PageFooter/>
    <br/>
    </>
  );
}
