import React, { useState } from "react";
import { firestore } from "../firebase.config";
import * as XLSX from "xlsx";
import {
  writeBatch,
  // getDocs,
  // query,
  // collection,
  // where,
} from "firebase/firestore";

import {
  IdMatcher,
  ProductMasterID,
  ProductMaster,
  PinIdMatcher,
  Pincode,
  PincodeID,
  UpdateMasterData,
  collectionIdMatcher,
} from "../DatabaseAPI/DatabaseQuery";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Navigate } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { getIsAdmin, getIsOps } from "../utils/sessionCheck";
import NavBar from "./OldNavBar";
import NavBar2 from "../components/Navbar2";

const ExcelUpload = () => {
  const [file, setFile] = useState("");
  const [filetype, setFileType] = useState("");

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const handleChange = (event) => {
    setFileType(event.target.value);
    // generatePincodeList();
  };

  const handleFile = () => {
    if (filetype === "Product_Master") {
      // handleUpload();
      handleExcelBulkUpload("Product_Master");
    } else if (filetype === "Pincode_Master") {
      // handlePincodeUpload();
      handleExcelBulkUpload("PinCode_Master");
    } else if (filetype === "SuccessPartnerAccess"){
      // handleSuccessPartnerAccessUpload();
      handleExcelBulkUpload("SuccessPartnerAccess")

    } else if (filetype === "ProdSPMappingCollection"){
        handleExcelBulkUpload("ProdSPMappingCollection");
    } 
    
    else {
      console.error("Unknown file type:", filetype);
    }
  };

  const handleUpload = () => {
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Upload data to Firestore in bulk
      // Get a new write batch
      const batch = writeBatch(firestore);

      // Create an array to hold the promises of getDocs() calls
      const getDocsPromises = [];

      jsonData.forEach((row) => {
        const promise = new Promise(async (resolve, reject) => {
          try {
            const querySnapshot = await IdMatcher(row);
            resolve({ row, querySnapshot });
          } catch (error) {
            reject(error);
          }
        });

        getDocsPromises.push(promise);
      });

      try {
        // Wait for all getDocs() calls to complete
        const results = await Promise.all(getDocsPromises);

        results.forEach(({ row, querySnapshot }) => {
          if (!querySnapshot.empty) {
            const docSnapshot = querySnapshot.docs[0]; // Assuming you only expect one document
            const docId = docSnapshot.id;
            const docRef = ProductMasterID(docId);
            batch.update(docRef, {
              // prodName: row.prodName,
              // prodTechName: row.prodTechName,
              // prodPromotionPriority: row.prodPromotionPriority,
              // prodDescription: row.prodDescription,
              // prodAdditionalDetails: row.prodAdditionalDetails,
              // prodConstitutionType: row.prodConstitutionType,
              // prodCountryOfOrigin: row.prodCountryOfOrigin,
              // prodWarrantyDtls: row.prodWarrantyDtls,
              // prodMfgDiscontinued: row.prodMfgDiscontinued,
              // pincodes: row.pincodes,
              // prodProductCategory: row.prodProductCategory,
              // prodMRPWithGST: row.prodMRPWithGST,
              // prodCGSTPercentage: row.prodCGSTPercentage,
              // prodSGSTPercentage: row.prodSGSTPercentage,
              // prodUsageType: row.prodUsageType,
              // prodBrandName: row.prodBrandName,
              // prodBrandID: row.prodBrandID,
              // prodAmpereRating: row.prodAmpereRating,
              prodAdditionalDetails: row.prodAdditionalDetails,
              prodAmpereHourRating: row.prodAmpereHourRating,
              prodAmpereRating: row.prodAmpereRating,
              prodBrandID: row.prodBrandID,
              prodBrandName: row.prodBrandName,
              prodCityAvail: row.prodCityAvail,
              prodConstitutionType: row.prodConstitutionType,
              prodCountryOfOrigin: row.prodCountryOfOrigin,
              prodCurrAvailabilityStatus: row.prodCurrAvailabilityStatus,
              prodDescription: row.prodDescription,
              prodDimensions: row.prodDimensions,
              prodDP1WithGST: row.prodDP1WithGST,
              prodDP2WithGST: row.prodDP2WithGST,
              prodDP3WithGST: row.prodDP3WithGST,
              prodDPWithGST: row.prodDPWithGST,
              prodGSTPercent: row.prodGSTPercent,
              prodHSNCode: row.prodHSNCode,
              prodID: row.prodID,
              prodImageThumbnailLoc: row.prodImageThumbnailLoc,
              prodKeywords: row.prodKeywords,
              prodKVA: row.prodKVA,
              prodLastUpdatedBy: row.prodLastUpdatedBy,
              prodLastUpdatedOn: row.prodLastUpdatedOn,
              prodMRP: row.prodMRP,
              prodName: row.prodName,
              prodPINCodeNotAvail: row.prodPINCodeNotAvail,
              prodProductCategory: row.prodProductCategory,
              prodPromotionPriority: row.prodPromotionPriority,
              prodRule: row.prodRule,
              prodTechName: row.prodTechName,
              prodTechnicalSpecs: row.prodTechnicalSpecs,
              prodUsageType: row.prodUsageType,
              prodVoltage: row.prodVoltage,
              prodWarrantyDtls: row.prodWarrantyDtls,
              progWeightInKgs: row.progWeightInKgs,
              index: row.index,
            });
          } else {
            const docRef = ProductMaster();
            console.log("updated Product master collection");
            batch.set(docRef, row);
          }
        });

        await batch.commit();
        console.log("Bulk upload successful!");
      } catch (error) {
        console.error("Error uploading documents: ", error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handlePincodeUpload = () => {
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Upload data to Firestore in bulk
      // Get a new write batch
      const batch = writeBatch(firestore);

      // Create an array to hold the promises of getDocs() calls
      const getDocsPromises = [];

      jsonData.forEach((row) => {
        const promise = new Promise(async (resolve, reject) => {
          try {
            const docSnap = await PinIdMatcher(row);
            resolve({ row, docSnap });
          } catch (error) {
            reject(error);
          }
        });

        getDocsPromises.push(promise);
      });

      try {
        // Wait for all getDocs() calls to complete
        const results = await Promise.all(getDocsPromises);

        results.forEach(({ row, docSnap }) => {
          if (docSnap.exists()) {
            // const docSnapshot = querySnapshot.docs[0]; // Assuming you only expect one document
            const docId = docSnap.id;
            const docRef = PincodeID(docId);
            batch.update(docRef, {
              pincode: row.pincode,
              Districtname: row.Districtname,
              Taluk: row.Taluk,
              officename: row.officename,
              statename: row.statename,
              longitude: row.longitude,
              latitude: row.latitude,
            });
          } else {
            const docRef = Pincode(String(row.pinID));
            batch.set(docRef, row);
          }
        });

        await batch.commit();
        console.log("Bulk upload successful!");
      } catch (error) {
        console.error("Error uploading documents: ", error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleExcelBulkUpload = (collectionName) => {

    console.log("Collection name and ID field received as:",collectionName);
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Upload data to Firestore in bulk
      // Get a new write batch
      const batch = writeBatch(firestore);

      // Create an array to hold the promises of getDocs() calls
      const getDocsPromises = [];

      // collectionNameIDField = "pinID"
      // collectionName = "PinCode_Master"
      // jsonData.forEach((row) => {
      //   const promise = new Promise(async (resolve, reject) => {
      //     try {
      //       console.log("Calling collectionIdMatcher with values", row, collectionNameIDField, collectionName);
      //       const querySnapshot = await collectionIdMatcher(row, collectionNameIDField, collectionName);
      //       resolve({ row, querySnapshot });
      //     } catch (error) {
      //       reject(error);
      //     }
      //   });

          
      //   getDocsPromises.push(promise);
      // });

      try {
        // Wait for all getDocs() calls to complete
        // const results = await Promise.all(getDocsPromises);

        console.log("Calling UpdateMasterData with values", collectionName);
        // results.forEach(({ row, querySnapshot }) => {
          jsonData.forEach((row) => {
            // const docRef = ProductMaster();
            const docRef = UpdateMasterData(collectionName);
            
            console.log("updated the collection", collectionName);
            batch.set(docRef, row);
          }
        );

        await batch.commit();
        console.log("Bulk upload successful!");
      } catch (error) {
        console.error("Error uploading documents: ", error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSuccessPartnerAccessUpload = () => {
    // if (!file) return;

    // const reader = new FileReader();

    // reader.onload = async (e) => {
    //   const data = new Uint8Array(e.target.result);
    //   const workbook = XLSX.read(data, { type: "array" });
    //   const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
    //   const sheet = workbook.Sheets[sheetName];
    //   const jsonData = XLSX.utils.sheet_to_json(sheet);

    //   // Upload data to Firestore in bulk
    //   // Get a new write batch
    //   const batch = writeBatch(firestore);

    //   // Create an array to hold the promises of getDocs() calls
    //   const getDocsPromises = [];

    //   jsonData.forEach((row) => {
    //     const promise = new Promise(async (resolve, reject) => {
    //       try {
    //         const docSnap = await PinIdMatcher(row);
    //         resolve({ row, docSnap });
    //       } catch (error) {
    //         reject(error);
    //       }
    //     });

    //     getDocsPromises.push(promise);
    //   });

    //   try {
    //     // Wait for all getDocs() calls to complete
    //     const results = await Promise.all(getDocsPromises);

    //     results.forEach(({ row, docSnap }) => {
    //       if (docSnap.exists()) {
    //         // const docSnapshot = querySnapshot.docs[0]; // Assuming you only expect one document
    //         const docId = docSnap.id;
    //         const docRef = PincodeID(docId);
    //         batch.update(docRef, {
    //           pincode: row.pincode,
    //           Districtname: row.Districtname,
    //           Taluk: row.Taluk,
    //           officename: row.officename,
    //           statename: row.statename,
    //           longitude: row.longitude,
    //           latitude: row.latitude,
    //         });
    //       } else {
    //         const docRef = Pincode(String(row.pinID));
    //         batch.set(docRef, row);
    //       }
    //     });

    //     await batch.commit();
    //     console.log("Bulk upload successful!");
    //   } catch (error) {
    //     console.error("Error uploading documents: ", error);
    //   }
    // };

    // reader.readAsArrayBuffer(file);
  }; // end of successpartnerAccess upload

  const navigate = useNavigate();

  return (
    <div>
      {/* <NavBar cartDisplayProp={'none'} */}
      <NavBar2 cartDisplayProp={'none'}
              showMenuBarProp={'none'}
       />
      <Stack sx={{ m: 5, width: "20%", justifyItems: "center" }}>
        
      { (getIsAdmin() || getIsOps()) && <>
        <input type="file" onChange={handleFileChange} accept=".xlsx, .csv, .xls" />
        <h2> Upload Excel File Only</h2>
        
        <FormControl sx={{ py: 2 }}>
          <InputLabel id="filetype" sx={{ p: 2 }}>
            Type
          </InputLabel>
          <Select
            labelId="filetype"
            id="filetype"
            value={filetype}
            label="File Type"
            onChange={handleChange}
          >
            <MenuItem value={"Product_Master"}>Product_Master</MenuItem>
            <MenuItem value={"Pincode_Master"}>Pincode_Master</MenuItem>
            <MenuItem value={"SuccessPartnerAccess"}>SuccessPartnerAccess</MenuItem>
            <MenuItem value={"ProdSPMappingCollection"}>ProdSPMappingCollection</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" sx={{}} onClick={handleFile}>
          Upload
        </Button>
        </>
      }
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          color="success"
          onClick={() => navigate("/6g2hb")}
        >
          Order Processing
        </Button>


        <Button
          variant="contained"
          sx={{ mt: 2 }}
          color="success"
          onClick={() => navigate("/4g3hb")}
        >
          Enquiry Review
        </Button>

        <Button
          variant="contained"
          sx={{ mt: 2 }}
          color="success"
          onClick={() => navigate("/3g3hb")}
        >
          Reassign Service Provider
        </Button>
        
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          color="success"
          onClick={() => navigate("/6g3hb")}
        >
          Carousle Configurator
        </Button>


      </Stack>
    </div>
  );
};

export default ExcelUpload;
