import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

function AlphanumericInput({ onValueChange, label = "Alphanumeric Input" }) {
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        const newValue = event.target.value;

        // Handle empty string case for clearing the input
        if (newValue === '') {
            setValue(newValue);
            onValueChange(newValue); // Notify parent of the change
            return;
        }

        // Regular expression to match alphanumeric input including spaces
        const regex = /^[a-zA-Z0-9\s]*$/;

        // Validate the value against the regular expression, length constraint, and trim check
        if (regex.test(newValue) && newValue.length <= 20 && newValue.trim() !== '') {
            setValue(newValue);
            onValueChange(newValue); // Notify parent of the change
        }
    };

    return (
        <TextField
            label={label}
            value={value}
            onChange={handleChange}
            sx={{ mb: 3 }}
            variant="outlined"
            fullWidth
            placeholder={label}
            required
            margin='normal'
        />
    );
}

export default AlphanumericInput;
