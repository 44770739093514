import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

function EmailInput({ onValueChange, label = "Email Input" }) {
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(true); // State to track validity
    const [errorMessage, setErrorMessage] = useState(''); // State for error message

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        onValueChange(newValue); // Notify parent of the change

        // Regular expression to match a valid email format
        const regex = /^[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/;

        // Check for exactly one '@' sign
        const isSingleAt = (newValue.match(/@/g) || []).length === 1;

        // Validate email and update states
        if (regex.test(newValue) && isSingleAt) {
            setIsValid(true);
            setErrorMessage('');
        } else {
            setIsValid(false);
            setErrorMessage('Invalid email format. Please ensure it contains exactly one "@" sign.');
        }
    };

    return (
        <div>
            <TextField
                label={label}
                value={value}
                onChange={handleChange}
                sx={{ mb: 3 }}
                variant="outlined"
                fullWidth
                placeholder={label}
                required
                margin='normal'
                error={!isValid} // Set error state for the TextField
            />
            {!isValid && <FormHelperText error>{errorMessage}</FormHelperText>}
        </div>
    );
}

export default EmailInput;
