import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

function GstValidation({ onValueChange, label = "GST Number" }) {
    const [value, setValue] = useState('');
    const theme = useTheme();

    const handleChange = (event) => {
        const newValue = event.target.value;

        
        if (newValue === '') {
            setValue(newValue);
            onValueChange(newValue); 
            return;
        }

        // Regular expression to match alphanumeric input including spaces and dashes
        const regex = /^[a-zA-Z0-9\s-]*$/;

        // Validate the value against the regular expression and length constraint
        if (regex.test(newValue) && newValue.length <= 15) {
            setValue(newValue);
            onValueChange(newValue); 
        }
    };

    return (
        <TextField
            label={label}
            value={value}
            onChange={handleChange}
            sx={{
                mb: 3,
                mt: 2,
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: value.length > 0 && value.length < 15 ? theme.palette.error.main : undefined,
                    },
                   
                    '&.Mui-focused fieldset': {
                        borderColor: value.length > 0 && value.length < 15 ? theme.palette.error.main : undefined,
                    },
                },
            }}
            variant="outlined"
            fullWidth
            placeholder={label}
            margin="normal"
            inputProps={{ maxLength: 15 }} // Limit input length directly in the input
        />
    );
}

export default GstValidation;
