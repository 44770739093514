// DrawerMenu.js
import React, { useState } from 'react';
import { Box, Collapse, Divider, Drawer, List, ListItem, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo1 from "../svgicons/ReUrjaLogo_v1.svg";
import HomeIcon from "@mui/icons-material/Home";

const DrawerMenu = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();
  
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const handleMenuItemClick = (item) => {
    console.log(item); // Handle item click (you can replace this with your own logic)
    toggleDrawer(false)(); // Close the drawer after selecting an item
  };

  //function to navigate to home
  const handleHomeClick = () => {
		navigate("/home");
	};

  //function to navigate to orders of customer
	const handleTrackOrderClick = () => {
		navigate("/orders");
	};

  //function to navigate to knowBs of customer
  const handleKnowledbaseClick = () => {
		navigate("/knowBs");
	};
  /**
   * Below Made Anchor for opening and handling SubMenu using MeunItem
   * 
   */
  // Function to toggle submenu
  const toggleSubmenu = () => {
    setOpenSubmenu(!openSubmenu);
  };

  const handleEnquiryClick = (type) => {
		navigate("/enq", { state:{formType:type} });
	};

  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
      <div style={{ color: "green",width: 250, marginTop: '16px' }}>
      <img 
          src={Logo1} 
          alt="Description" 
          style={{ width: '100%', height: 'auto', marginBottom: 16 }} 
        />
        <Divider style={{ height: '2px', backgroundColor: 'Green' }}/>
        <MenuItem style={{ color: "#f79646",fontWeight:"bold"}} onClick={handleHomeClick}>
        <HomeIcon style={{ marginRight: 8 }} />
        Home
        </MenuItem>
        <Divider />
        <MenuItem style={{fontWeight:"bold"}}  onClick={toggleSubmenu}>Enquiry Form{openSubmenu ?
        <Typography variant="caption" sx={{ color: "orange" }}>&nbsp;ʌ</Typography>   :
        <Typography variant="caption" sx={{ color: "orange" }}>&nbsp;v</Typography>
      }</MenuItem>
      {
        openSubmenu ? null : <Divider />
      }

        <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component="div"  onClick={() => handleEnquiryClick("On Grid Solar")} style={{ paddingLeft: '32px' }}>
              On Grid Solar
            </ListItem>
            <ListItem component="div"  onClick={() => handleEnquiryClick("Green Open Access")} style={{ paddingLeft: '32px' }}>
              Green Open Access
            </ListItem>
            <ListItem component="div"  onClick={() => handleEnquiryClick("Off Grid Solar")} style={{ paddingLeft: '32px' }}>
              Off Grid Solar
            </ListItem>
            <ListItem component="div"  onClick={() => handleEnquiryClick("Solar Plant Project Management")} style={{ paddingLeft: '32px' }}>
              Project Management
            </ListItem>
            <Divider/>

            <ListItem component="div"  onClick={() => handleEnquiryClick("AMC for Battery")} style={{ paddingLeft: '32px' }}>
              Battery AMC
            </ListItem>
            <ListItem component="div"  onClick={() => handleEnquiryClick("AMC for Solar Plant")} style={{ paddingLeft: '32px' }}>
              Solar AMC
            </ListItem>
            <ListItem component="div"  onClick={() => handleEnquiryClick("AMC for Diesel Generator Set")} style={{ paddingLeft: '32px' }}>
              DG Set AMC
            </ListItem>
            <Divider/>

            <ListItem component="div"  onClick={() => handleEnquiryClick("Bulk LED Requirements")} style={{ paddingLeft: '32px' }}>
              LED
            </ListItem>
            <ListItem component="div"  onClick={() => handleEnquiryClick("Automation Requirements")} style={{ paddingLeft: '32px' }}>
              Automation
            </ListItem>
            <Divider/>

            <ListItem component="div"  onClick={() => handleEnquiryClick("Energy Audit")} style={{ paddingLeft: '32px' }}>
              Energy Audit
            </ListItem>
            <ListItem component="div"  onClick={() => handleEnquiryClick("Green Energy Consultancy")} style={{ paddingLeft: '32px' }}>
              Green Energy Consultancy
            </ListItem>
            <ListItem component="div"  onClick={() => () => handleEnquiryClick("Training Requirements")} style={{ paddingLeft: '32px' }}>
             Training
            </ListItem>
            <Divider />
          </List>
        </Collapse>
        
        <MenuItem style={{fontWeight:"bold"}}  onClick={handleTrackOrderClick}>Track Orders</MenuItem>
        <Divider />
        <MenuItem style={{fontWeight:"bold"}}  onClick={handleKnowledbaseClick}>Knowledge Base</MenuItem>
        <Divider />
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
