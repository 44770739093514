// CheckoutPage.jsx
import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Grid,
  Divider,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { CartState } from "../context/ShopContext";
import NavBar from "./OldNavBar";
import DeleteIcon from "@mui/icons-material/Delete";
import PageFooter from "../footer/PageFooter";
import {
  OrderMaster,
  EditOrder,
  EditOrderProducts,
  UpdateOrder,
  incrementCountOfOrder,
} from "../DatabaseAPI/DatabaseQuery";
import {
  getSessionUserPhoneNumber,
  getSessionDeliveryPinCode,
  getUserEmail,
  setSessionDeliveryPinCode,
  setSessionUserPhoneNumber,
  getIsSPOps,
  setSessionSPOpsGrpID,
  getSessionSPOpsGrpID,
  getSessionSelectPinCodeAllData,
} from "../utils/sessionCheck";
import { v4 as uuidv4 } from "uuid";
import { nanoid } from "nanoid";
import { useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";


import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ThankYouPage from "./Thankyoupage";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import NavBar2 from "../components/Navbar2";
import PhoneNumber from "../validation/PhoneNumber";
import GstValidation from "../validation/GstValidation";
import AlphanumericInput from "../validation/AlphanumericInput";
import Notes from "../validation/Notes";
import { logEvent } from "firebase/analytics";
import { appAnalytics } from "../firebase.config";

const typostyle = {
  width: "100%",
  position: "fixed",
  bottom: 0,
  padding: 2,
  textAlign: "center",
  marginLeft: "10px",
  marginRight: "10px",
};

const buttonstyle = { marginLeft: "5px", marginRight: "5px", float: "right" };

const CheckoutPage2 = () => {

  // Show the success or failuer message as snackbar
  const [open, setOpen] = React.useState(false);
  const [editOrder, setEditOrder] = useState(false);
  //let ordAlertMsg = "Your Order Number - ";
  const [ordAlertMsg, setOrdAlertMsg] = React.useState("");
  const [msgType, setMsgType] = React.useState("success");
  const [placeOrderState, setPlaceOrderState] = useState(false);
  const [ordList, setOrdList] = useState([]);
  const location = useLocation();
  const [productIdsString, setProductIdsString] = useState('');
  const [productQtyString, setProductQtyString] = useState('');
  
  

  const { cart, setCart} =CartState();
  
  useEffect(() => {
    return () => {
      // Perform any necessary cleanup
    };
  }, [cart]);
    

  const memoizedCart = useCallback(()=>{
    const productsIds = cart.map(products => products.prodID);
    //storing all product item qty and mapping it to database
    const productqty = cart.map(products => products.qty);
    setProductIdsString(productsIds);
    setProductQtyString(productqty);
   
  },[cart])

    useEffect(()=>{
        //here mapping each and every item added in cart
      memoizedCart();
    },[memoizedCart])
    
   

  const emptyCart = () => {
    setCart([]); 
  };

  const removeFromCart = (prodDetailID) => {
    setCart((currentCart) => {
      // Filter out the item with the matching productId
      const updatedCart = currentCart.filter(
        (item) => item.prodID !== prodDetailID
      );

       // capture gAnalytics event of new filter seletion
       const anlyticsString = ("Checkout page - Product deleted from cart ");
       console.log(anlyticsString);
       logEvent(appAnalytics, anlyticsString);

      return updatedCart;
    });
  };

  // Decrement cart quantity -- START
  const decrementQtyOfCartItem = (prodDetailID) => {
    setCart((currentCart) => {
      // Use map to create a new array with the updated item
      const renewedCart = currentCart.map((item) => {
        if (item.qty > 1 && item.prodID === prodDetailID) {
          return { ...item, qty: item.qty - 1 }; // Return a new item with updated qty
        }
        return item; // Return the item as is if prodID doesn't match
      });

      return renewedCart; // Return the new cart
    });
  };

  // Decrement cart quantity - END

  // Increment cart quantity -- START
  const incrementQtyOfCartItem = (prodDetailID) => {
    setCart((currentCart) => {
      // Use map to create a new array with the updated item
      const renewedCart = currentCart.map((item) => {
        if (item.prodID === prodDetailID) {
          if(item.qty!=100)
          {
            return { ...item, qty: item.qty + 1 }; // Return a new item with updated qty
          }
          
        }
        return item; // Return the item as is if prodID doesn't match
      });

      return renewedCart; // Return the new cart
    });
  };
  // Increment cart quantity - END

  //update installation required value in cart record - START
  const updateProdInstallationReqd = (prodDetailID, isInstallRqdFlag) => {
    setCart((currentCart) => {
      // Use map to create a new array with the updated item
      const renewedCart = currentCart.map((item) => {
        if (item.prodID === prodDetailID) {
          return { ...item, isProdInstallationReqd: isInstallRqdFlag }; // Return a new item with updated qty
        }
        return item; // Return the item as is if prodID doesn't match
      });

      return renewedCart; // Return the new cart
    });
  };
  //update installation required value in cart record - END

  //update exchange required value in cart record - START
  const updateProdExchangeAvailable = (prodDetailID, isInstallRqdFlag) => {
    setCart((currentCart) => {
      // Use map to create a new array with the updated item
      const renewedCart = currentCart.map((item) => {
        if (item.prodID === prodDetailID) {
          return { ...item, isProdExchangeAvailable: isInstallRqdFlag }; // Return a new item with updated qty
        }
        return item; // Return the item as is if prodID doesn't match
      });
      return renewedCart; // Return the new cart
    });
  };

  useEffect(() => {
    if (location.state) {
        setEditOrder(true);
      editList();
    }
    
  }, []);

  const editList = async () => {
    const ListOrd = await EditOrder(location.state.ordID);
    setOrdList(ListOrd);
  };

  

  useEffect(() => {
    if (ordList.length > 0) {
      updatePage();
    }
    return () => {
      // Perform any necessary cleanup
    }
  }, [ordList]);

  //update Page Functionality
  const updatePage = () => {
    setUserData({
      name: ordList[0].ordUsrName,
      address: ordList[0].ordCustomerDeliveryAddress,
      phoneNumber: ordList[0].ordPhoneNumber,
      gstNo: ordList[0].ordCustomerGSTNumber,
      pincode:ordList[0].ordPinCode,
      spUserGrpId: ordList[0].ordSPGrpID,
    });

  

    const nestedOrders = Object.entries(ordList[0].ordBook).reduce(
      (acc, [key, value], index) => {
        acc[index] = {
          prodID: key,
          prodGSTPercent: value.ordProdGSTPercentage,
          prodName: value.ordProdName,
          qty: value.ordQty,
          prodOP1WithGST: value.ordSubTotal / value.ordQty,
          isProdInstallationReqd: value.ordIsProdInstallationReqd,
          isProdExchangeAvailable: value.ordIsProdExchangeAvailable,
        };
        return acc;
      },
      []
    );

   

    setCart(nestedOrders);
    
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //snack bar code completed

  const [userData, setUserData] = useState({
    name: "",
    address: "",
    phoneNumber: "",
    gstNo: "",
    pincode: "",
    spUserGrpId:"", // added on 8th jun 2024
  });

  const addedTax = 0.0; // Replace with your tax value

  const calculateTotal = () => {
    const subtotal = cart.reduce(
      (acc, product) => acc + product.prodOP1WithGST * product.qty,
      0
    );
    const total = subtotal + (subtotal * addedTax) / 100;
    return total.toFixed(2);
  };
  const navigate = useNavigate();


  const sendOrderAcknowledgementEmail = async (email, apiKey,orderReferenceID,orderDetails) => {
    
    const jsonPayload = {
      email,
      subject: `Acknowledgement - Order Reference -- ${orderReferenceID}`,
      textbody: `Thank you for your order. Your order ID is ${orderReferenceID}.`, // this is not a part of the final email
      htmlbody: `
        <div style="font-family: Arial, sans-serif; color: #333;">
          <h2 style="color: #4CAF50;">Thank you for your order!</h2>
          <h4>Your order Reference ID is <strong>${orderReferenceID}</strong>.</h4>
          <h3>Order Details:</h3>
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr style="background-color: #f2f2f2;">
                <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Order Id</th>
                <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Product Name</th>
                <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Quantity</th>
                <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Price</th>
              </tr>
            </thead>
            <tbody>
              ${orderDetails.map(item => `
                <tr>
                  <td style="padding: 8px; border: 1px solid #ddd;"><strong>${item.id}</strong>.</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${item.prodName}</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">${item.qty}</td>
                  <td style="padding: 8px; border: 1px solid #ddd;">₹ ${item.prodOP1WithGST.toFixed(2)*item.qty}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
          <p><strong>Total Amount Payable:</strong> ₹ ${orderDetails.reduce((total, item) => total + item.prodOP1WithGST * item.qty, 0).toFixed(2)}</p>
          <h3>Order Tracking:</h3>
          <p>You can track your order using the following link: <a href="${process.env.REACT_APP_PRIMARY_DOMAIN}/orders" style="color: #4CAF50;">Track Order</a></p>
        </div>
      `,
      apiKey,
    };
  
    const formData = new FormData();
    formData.append('jsonPayload', JSON.stringify(jsonPayload));
    try {
      const response = await axios.post("https://email-a306.onrender.com/send-email", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        return 'Email sent successfully!';
      } else {
        return 'Error sending email.';
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        return 'Invalid API key';
      } else {
        return 'Error sending email.';
      }
    }
  };

//function gets called when submit is clicked for placing order
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    try {
      incrementCountOfOrder(productIdsString,productQtyString);
      // Generate a common reference ID for all orders
      const ordReferenceID = "ORD-REF-" + nanoid(); // Common reference ID for all orders
      const emailID = getUserEmail();
      const ordDateAndTime = new Date();
      const ordLastUpdatedOn = new Date();
      const ordPinCode = getSessionDeliveryPinCode();
      const ordSPGrpID = getSPUserGrpID4SelectPinCode();
      
      // Iterate through the cart and create individual orders for each product
      for (const product of cart) {
        const ordID = "ORD-" + nanoid(); // Generate a unique order ID for each product
        
        const data = {
          ordID, // Unique order ID for the product
          ordReferenceID, // Common reference ID for all products
          ordProdId:product.prodID,
          ordProdName: product.prodName,
          ordQty: product.qty,
          ordHSNNumber: product.prodHSNCode, // ????
          ordProdGSTPercentage: product.prodGSTPercent, // ????
          ordSubTotal: product.prodOP1WithGST * product.qty, // ????
          // ordExchngePrice: (product.prodOP1WithGST - product.prodIndicativeExchngPrice), // Added on 7th June 2024
          ordExchngePrice: (product.prodIndicativeExchngPrice), // Added on 10Oct2024
          ordisProdInstallationReqd: product.isProdInstallationReqd,
          ordisProdExchangeAvailable: product.isProdExchangeAvailable,
          ordOverallDiscountPercentage: "", // Common across all orders
          ordOverallOtherCharges: "", // Common across all orders
          ordTotalAmountPayable: calculateTotal(), // Adjust based on individual product if needed
          ordOverallStatus: 1, // Order status
          ordEmailID: emailID,
          ordUsrName: userData.name,
          ordDateAndTime, // Common date and time for all orders
          ordClosureDateAndTime: "",
          ordLastUpdatedOn, // Common last updated timestamp for all orders
          ordLastUpdatedBy: emailID,
          ordCustomerGSTNumber: userData.gstNo,
          ordCustomerDeliveryAddress: userData.address,
          ordPaymentTransactionID: "", // ????
          ordPhoneNumber: userData.phoneNumber,
          ordIsActive: true,
          ordIsDeleted: false,
          ordPinCode, // Common pin code for all orders
          ordSPGrpID, // Common SP group ID for all orders
        };
        
        // Add the individual product order to the database
        const ordAdded = await OrderMaster(data);
      }
  
      // Show a confirmation message to the user
      setOrdAlertMsg("Your Order Reference Number - " + ordReferenceID);
      setOpen(true);
      setPlaceOrderState(true);

         // capture gAnalytics event of new filter seletion
         const anlyticsString = ("Checkout page - Order placed by the user ");
         console.log(anlyticsString);
         logEvent(appAnalytics, anlyticsString);
  
      // Clear the cart and navigate to the thank you page
      // Send order confirmation email with all products under the reference ID
      
      

      const apiKey = 'qwertyuiop'; 
      const emailResponse =  sendOrderAcknowledgementEmail(emailID, apiKey, ordReferenceID, cart);
      
      navigate("/thanku", { state: { orderReferenceID: ordReferenceID } });
      emptyCart();
  
      
      
    } catch (error) {
      console.error("Error uploading data:", error);
      setOrdAlertMsg(
        "Error Processing your order at this moment. Please try again later"
      );
      setMsgType("error");
      setOpen(true);
      setPlaceOrderState(false);
    }
  };

  //This function handels updation of the order with respecet to the increment and decrement count of cart 
  const handleUpdateOrder = async () => {
    try {
      const emailID = getUserEmail();
      const data = {
        ordBook: [],
        ordOverallDiscountPercentage: "",
        ordOverallOtherCharges: "",
        ordTotalAmountPayable: calculateTotal(),
        ordOverallStatus: 1,
        ordUsrName: userData.name,
        ordClosureDateAndTime: "",
        ordLastUpdatedOn: new Date(),
        ordLastUpdatedBy: emailID,
        ordCustomerGSTNumber: userData.gstNo,
        ordCustomerDeliveryAddress: userData.address,
        ordPaymentTransactionID: "", // ?????
        ordPhoneNumber: userData.phoneNumber,
        ordIsActive: true,
        ordIsDeleted: false,
        
      };

      data.ordBook = cart.reduce((acc, product) => {
        acc[product.prodID] = {
          ordProdName: product.prodName,
          ordQty: product.qty,
          ordHSNNumber: product.prodHSNCode, // ?????
          ordProdGSTPercentage: product.prodGSTPercent, // ?????
          // ordSubTotal: product.prodMRP * product.qty, // commented on 7th june 2024
          ordSubTotal: product.prodOP1WithGST * product.qty, // ?????
          //ordExchngePrice: (product.prodOP1WithGST - product.prodIndicativeExchngPrice),     // added on 7th june 2024
          ordExchngePrice: (product.prodIndicativeExchngPrice),     // updated on 10Oct2024

          ordisProdInstallationReqd: product.IsProdInstallationReqd,
          ordisProdExchangeAvailable: product.IsProdExchangeAvailable,
        };
        return acc;
      }, {});
      const updatedOrd = await UpdateOrder(ordList[0].id, data);
      setOrdAlertMsg("Order Updated - " + location.state.ordID);
      setOpen(true);
      setPlaceOrderState(true);
      setCart([]);
      setUserData({
        name: "",
        address: "",
        phoneNumber: "",
        gstNo: "",
        pincode: "",
        deliveryNote: "",
        spUserGrpId:"",
      });
    } catch (error) {
      console.error("Error uploading data:", error);
      setOrdAlertMsg(
        "Error Processing your order at this moment. Please try again later"
      );
      setMsgType("error");
      setOpen(true);
      setPlaceOrderState(false);
    }
  };
 // exchange should be available by default

  // Calculate a potential discount (you can adjust this logic as needed)
  const originalPrice = 12245; // Example original price
  const discountPercentage = 20; // Example discount percentage
  const discountedPrice =
    originalPrice - originalPrice * (discountPercentage / 100);

  const exchangeAHRateInINR = 10;


  // below function is to get the SPUserGrp id corresponding to the pincode selected by the customer at the time
  // of login
  const getSPUserGrpID4SelectPinCode = () => {

    const pinDoc = getSessionSelectPinCodeAllData();

    
   
    const tempSPGrpID = JSON.parse(pinDoc).successPartnerSPGrpID;


    return tempSPGrpID;

  }

  // const handlePhoneNumberChange = (e) => {
  //   const value = e.target.value;
  //   if (/^\d*$/.test(value)) { // Allow only digits
  //     setUserData({ ...userData, phoneNumber: value });
  //     setSessionUserPhoneNumber(value);
  //   }
  // };

  const handleChangeGST = (value) => {
    
    
      setUserData({ ...userData, gstNo: value });
      
    
  };

  const handleValuePhoneNumber = (value) =>{
    setUserData({ ...userData, phoneNumber: value });
  }

  const handleChangeName = (value) =>{
    setUserData({...userData, name: value})
  };

  const handleChangeAddress = (value) =>{
    setUserData({...userData, address: value})
  }

  const handleChangeDeliveryNotes = (value) =>{
    setUserData({...userData, deliveryNote: value})
  }

  //Function to check validity of field before placing ordre 
  const checkout = () =>
  {
    // console.log('User Data:', userData);
    // console.log('Cart:', cart);
    if(userData.name==null || userData.phoneNumber.length!=10 || userData.address=="" || cart.length===0)
    {
        setPlaceOrderState(true);
    }
    else if(userData.gstNo.length!=15)
    {
      setPlaceOrderState(true);
      if(userData.gstNo.length==0)
      {
        setPlaceOrderState(false);
      }
    }
    else{
      setPlaceOrderState(false);
      return true;
    }
  }

  useEffect(()=>{
    checkout();
  },[userData, cart])

  return (
    <>
    
      <NavBar2 cartDisplayProp={'show'}
              showMenuBarProp={'show'} 
              pinCodeBoxDisplay={'none'}/>
      <Box
        maxWidth="xl"
        height="auto"
        margin={2}
        component="form"
        onSubmit={handleSubmit}
      >
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            margin: "auto",
            border: "2px solid #07b307",
            height: "auto",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Checkout Page
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }}>
              <TableHead sx={{ bgcolor: "lightgreen" }}>
                <TableRow style={{ border: "2px solid #07b307" }}>
                  <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Product Name
                  </TableCell>

                  <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Capacity
                  </TableCell>

                  <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Offer Price
                  </TableCell>

                  <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Offer Price <br/> <Typography fontSize={10} color='blue'> with Exchange* </Typography>
                  </TableCell>

                  <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Sub-Total
                  </TableCell>
              
                  {/* temporarily commented on 11Oct2024 */}
                  {/* <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Exchange?
                  </TableCell> */}

                  {/* temporarily hiding the installation option in Release 1 */}

                  {/* <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700}}
                  >
                    Installation?
                  </TableCell> */}


                  <TableCell
                    style={{ border: "2px solid #07b307", fontWeight: 700 }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((product) => (
                  <TableRow key={product.prodID}>
                    <TableCell>{product.prodName}</TableCell>

                    <TableCell>{product.prodAmpereHourRating}</TableCell>

                    <TableCell>
                      <meta charSet="UTF-8" />₹
                      {product.prodOP1WithGST.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <meta charSet="UTF-8" />₹
                      {/* {product.prodOP1WithGST -
                        product.prodIndicativeExchngPrice}         */}
                      {product.prodIndicativeExchngPrice}        
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={0}>
                        <IconButton
                          aria-label="decrease"
                          size="small"
                          onClick={() => decrementQtyOfCartItem(product.prodID)}
                        >
                          <RemoveIcon fontSize="inherit" />
                        </IconButton>
                        <b>{product.qty}</b>
                        {/* {tempProdQty} */}
                        <IconButton
                          aria-label="increase"
                          size="small"
                          onClick={() => incrementQtyOfCartItem(product.prodID)}
                        >
                          <AddIcon fontSize="inherit" />
                        </IconButton>
                      </Stack>
                    </TableCell>             
                    <TableCell>
                      <meta charSet="UTF-8" />₹
                      {(product.prodOP1WithGST * product.qty).toFixed(2)}
                    </TableCell>
                    {/* <TableCell>
                      <Switch                  
                        size="small"
                        defaultChecked
                    
                        onChange={(e) => {
                          const chkedVal = e.target.checked;
                          console.log(
                            "<<< prodexchange requed flag value to be set = ",
                            chkedVal,
                            "and prod id is = ",
                            product.prodID
                          );
                         
                          updateProdExchangeAvailable(product.prodID, chkedVal);
                        }}
                       
                      />
                    </TableCell> */}

                        {/* temporarily hiding the installation option in Release 1 */}

                    {/* <TableCell>
                      <Switch
                    
                        size="small"
               
                        onChange={(e) => {
                          const chkedVal = e.target.checked;
                     
                          updateProdInstallationReqd(product.prodID, chkedVal);
                        }}
                      />
                    </TableCell> */}


                    <TableCell>
                      <IconButton
                        onClick={() => removeFromCart(product.prodID)}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider style={{ margin: "20px" }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Delivery Details
              </Typography>
           
            <AlphanumericInput 
                
                onValueChange={handleChangeName} 
                label="Enter name"
                />


              <Notes
               onValueChange={(handleChangeAddress)}
                label="Address*" 
                maxLength={100}
                
              />
              
              <TextField
                label="Pincode"
                variant="outlined"
                disabled
                onChange={(e) => {
                  setUserData({ ...userData, pincode: e.target.value });
                  setSessionDeliveryPinCode(e.target.value);

                  setUserData({...userData, spUserGrpId: getSPUserGrpID4SelectPinCode()});
                }}
                // value={userData.pincode}
                // value={getSessionDeliveryPinCode()}
                defaultValue={getSessionDeliveryPinCode()}
                required
                sx={{mt:2}}
              />



              <PhoneNumber
                allowDecimals={false}
                allowNegative={false}
                min={0}
                max={10000000000}
                onValueChange={handleValuePhoneNumber}
                maxLength={10}
              />

            <GstValidation
                onValueChange={handleChangeGST} 
                label="GST Number"
              />

      
            </Grid>
            
            <Grid item xs={12} md={6}>
              <br></br>
              <br></br>


              <Notes
               onValueChange={(handleChangeDeliveryNotes)}
                label="Delivery Notes" 
                maxLength={100}
                
              />

              <Typography variant="h6" gutterBottom>
                Order Summary:
              </Typography>

              <Typography variant="body1">
                Total Items: {cart.reduce((acc, item) => acc + item.qty, 0)}
              </Typography>

         

              <Typography variant="h6" align="right">
                Total: <meta charSet="UTF-8" />₹{calculateTotal()}
              </Typography>
            </Grid>
          </Grid>
         
        </Paper>
        {editOrder && (
          <Button
            style={buttonstyle}
            variant="contained"
            color="success"
            disabled={placeOrderState}
            // style={{ float: "right",color: "success"}}
            sx={{}}
            onClick={handleUpdateOrder}
          >
            Update Order
          </Button>
        )}
        {!editOrder && (
          <Button
            style={buttonstyle}
            variant="contained"
            color="success"
            disabled={placeOrderState}
            // style={{ float: "right",color: "success"}}
            sx={{}}
            // onClick={() => emptyCart()}
            // onClick={() => navigate("/payment")}
            type="submit"
          >
            Place Order
          </Button>
        )}




        <br></br>

      
      </Box>

    
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={msgType}
          variant="filled"
          sx={{ width: "100%" }}
        >
        
          {ordAlertMsg}
        </Alert>
      </Snackbar>

      <Box margin={2} maxWidth={"70%"} height={"auto"}>
        <p>
          <Typography variant="h11" align="center">
            <b>
              <u>Notes:</u>
            </b>
            {/* &nbsp;Your order will be processed only after it is confirmed on this website. 
            Order once confirmed, cannot be cancelled. Order processing Partners
            will be directly accepting the payments upon Goods delivery at given address.
            All orders will be processed only on COD (Cash on Delivery) basis. 
            <b> Please verify the identity of the person before making any payments.</b>
            &nbsp; If Total order amount exceeds ₹ 15000/- then it will need a minimum of 
            50% of advance payment before deliverying the products at delivery address. */}
            
          </Typography>
        </p>
        <b>
          - Payment should be done in advance upon order confirmation.<br/>
          - All prices are inclusive of GST<br/>
          {/* - Delivery Charges may be extra <br/> */}
          - Installation charges will be extra<br/>
          <br></br>* - Exchange price is indicative provided the battery getting exchanged is
          of same AH/Type and is in good physical condition. Customer should pack the exchange items during return.
          <br></br># - Subtotal and Total is based on offer price only
        </b>
        <p>
          <Typography variant="h11" align="left" textAlign="center">
            <b>
              <u>Disclaimer: </u>
            </b>
            &nbsp;Brands showcased on this website are registered trademarks of those
            organizations. This website does not indicate or claim any credits for the same.
          </Typography>
        </p>
      </Box>

      <PageFooter />
    </>
  );
};

export default CheckoutPage2;
