import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

function Notes({ onValueChange, label = "Alphanumeric Input", maxLength = 20 }) {
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        const newValue = event.target.value;

        // Handle empty string case for clearing the input
        if (newValue === '') {
            setValue(newValue);
            onValueChange(newValue); // Notify parent of the change
            return;
        }

        // Updated regular expression to match alphanumeric input including spaces, full stops, divide signs, semicolons, and commas
        const regex = /^[a-zA-Z0-9\s.,;/]*$/;

        // Validate the value against the regular expression and length constraint
        if (regex.test(newValue) && newValue.length <= maxLength) {
            setValue(newValue);
            onValueChange(newValue); // Notify parent of the change
        }
    };

    return (
        <TextField
            label={label}
            value={value}
            onChange={handleChange}
            sx={{ mb: 3 }}
            rows={4}
            multiline
            variant="outlined"
            fullWidth
            placeholder={label}
            margin='normal'
        />
    );
}

export default Notes;
