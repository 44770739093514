import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SnackbarComponent = ({ open, message, severity }) => {
  return (
    <Snackbar 
      open={open} 
      autoHideDuration={4000} 
      // onClose={onClose} 
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert  severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
