// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, signInWithPhoneNumber, RecaptchaVerifier, GoogleAuthProvider, sendSignInLinkToEmail, signInWithEmailLink, isSignInWithEmailLink } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import {getAnalytics} from "firebase/analytics"; // enabling the google analytics for this app
import {
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  persistentLocalCache,
} from "firebase/firestore";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};


const app = initializeApp(firebaseConfig);
const appAnalytics = getAnalytics(app);

const provider = new GoogleAuthProvider();
export const googleProvider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account ",
});
export const auth = getAuth(app);
const firestore = getFirestore(app);
export const functions = getFunctions(app);
// export const storage = getStorage(app);
// export const firestore = getFirestore(app);
const storage = getStorage(app);

// const firestoreDb = initializeFirestore(app, {
//   localCache: persistentLocalCache(/*settings*/ {}),
//   cacheSizeBytes: CACHE_SIZE_UNLIMITED,
// });

export { provider, firestore, storage , createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  appAnalytics};
