import PageFooter from "./PageFooter";
import { Box, Button, Typography } from "@mui/material";
import webContactImg from "../images/WebSite_Contact.png";
import NavBar from "../pages/OldNavBar";
import {SendPlaceOrderEmail} from "../communication/emails/SendPlaceOrderEmail";
import NavBar2 from "../components/Navbar2";

export default function ContactUsPg() {

    return (
        <>
            
            <NavBar2 cartDisplayProp={'show'}
              showMenuBarProp={'show'}
            />
            
            {/* <Box
                component="img"
                sx={{
                    //height: 233,
                    //width: 350,
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    m: 5,
                    border: 1,
                    borderColor: 'lightgreen',
                    objectFit: "contain",
                }}
                alt="Contact us"
                src={webContactImg}
            /> */}

            <Box sx={{m:5}}>
                <Typography variant="h5"> <b>ReUrja Technologies LLP</b> </Typography>
                <br/>
                <br/>
                Locations: Pune, Nagpur (upcoming)
                <br/>
                <br/>
                Email Id - info@reurja.com
                <br/>
                <br/>
                Contact / WhatsApp Numbers: <br/>
                <ul>
                    <li>+91 94 2265 2285</li>
                    <li>+91 90960 39034</li>    
                </ul>
            </Box>

            <PageFooter />
        </>
    );

}