import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, Snackbar, TextField, Alert } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl} from "@mui/material";
import { UpdateEnquiryStatus, UpdateOrder, UpdateOrderStatus } from "../../DatabaseAPI/DatabaseQuery";

const ItemDrawerEnquiry = ({
	open,
	onClose,
	item
}) => {
	const statusMapping = {
		1: "New",
		2: "In-Review",
		3: "Closed",
		4: "Invalid",
	};

	const [selectedStatus, setSelectedStatus] = useState("");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success");
	const [editMode] = useState(false);
	const [editableItem, setEditableItem] = useState({});

	//Enquiry Status Updation function 
	const handleStatusChange = async (itemId, status) => {
		try {
      console.log("Item", itemId, "status", status);
      status = parseInt(status, 10);
      // console.log("Item", itemId, "status", status);
			await UpdateEnquiryStatus(itemId, status);//Updates Enquiry Statsu in DatabaseQuery Files
			setSelectedStatus(status);
			setSnackbarOpen(true);
			setSnackbarMessage("Enquiry status updated successfully");
		} catch (error) {
			setSnackbarOpen(true);
			setSnackbarMessage("Error updating Enquiry status");
			setSnackbarSeverity("warning");
			// console.error("Error updating order status: ", error);
		}
	};

	useEffect(() => {
		if (item) {
			console.log("Item", item);
			setSelectedStatus(item.enqStatus);
			// console.log("Selected Status", selectedStatus);
			setEditableItem({ ...item });
		}
	}, [item]);

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	// const handleEditClick = () => {
	// 	setEditMode(true);
	// };

	// const handleSaveClick = async () => {
	// 	setEditMode(false);
  //   UpdateOrder(item.id, editableItem);
	// 	setSnackbarOpen(true);
	// 	setSnackbarMessage("Order details updated successfully");
	// 	setSnackbarSeverity("success");
	// 	// console.log("Updated item details Falsely: ", editableItem);
	// };
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEditableItem((prev) => ({ ...prev, [name]: value }));
		// console.log("Item id:",item.id,"Updated item details: ", editableItem);
	};
  

	return (
		<Drawer
			anchor="bottom"
			open={open}
			onClose={onClose}
			PaperProps={{
				style: {
					height: "55%",
					padding: 20,
				},
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
				}}
			>
				<Typography variant="h6" style={{ flex: 1, textAlign: "left" }}>
					Enquiry Details
				</Typography>
                

				<IconButton onClick={onClose} aria-label="Close drawer">
					<CloseIcon />
				</IconButton>
			</div>

			{item ? (
				<div style={{ marginTop: 20 }}>
					<Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
						<Grid container spacing={2}>

                        <Grid item xs={12} sm={6}>
                                <Typography><strong>Enquiry Registered:</strong> {new Date(item.enqDateAndTime.seconds * 1000).toLocaleDateString()}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
									<Typography>
										<strong>Enquiry ID:</strong> {item.enqID}
									</Typography>
								
							</Grid>


                            <Grid item xs={12} sm={6}>
									<Typography>
										<strong>Enquiry Purpose:</strong> {item.enqPurpose}
									</Typography>
								
							</Grid>

                            <Grid item xs={12} sm={6}>
									<Typography>
										<strong>Enquiry Type:</strong> {item.enqTypeID}
									</Typography>
								
							</Grid>

                            <Grid item xs={12} sm={6}>
									<Typography>
										<strong>Enquiry Type Name:</strong> {item.enqTypeShortName}
									</Typography>
								
							</Grid>

							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Contact Email ID"
										name="enqContactEmailID"
										value={editableItem.enqContactEmailID}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Contact Email ID:</strong> {item.enqContactEmailID}
									</Typography>
								)}
							</Grid>
							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Contact Mobile Number"
										name="enqContactMobileNumber"
										value={editableItem.enqContactMobileNumber}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Contact Mobile Number:</strong> {item.enqContactMobileNumber}
									</Typography>
								)}
							</Grid>

							<Grid item xs={12} sm={6}>
								{editMode ? (
									<TextField
										fullWidth
										label="Contact Name"
										name="enqContactName"
										value={editableItem.enqContactName}
										onChange={handleInputChange}
									/>
								) : (
									<Typography>
										<strong>Contact Name:</strong> {item.enqContactName}
									</Typography>
								)}
							</Grid>

							
              <Grid item xs={12} sm={6}>
                <Typography><strong>Contact City:</strong> {item.enqContactCity}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography><strong>Contact Pincode:</strong> {item.enqContactPinCode ?item.enqContactPinCode:<>Not Provided</> }</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography><strong>Additional Remark:</strong> {item.enqAdditionalRemarks ?item.enqAdditionalRemarks:<>Not Provided</> }</Typography>
              </Grid>

              



							{/* Repeat the same pattern for other fields */}

							<Grid item xs={12} sm={6}>
								<FormControl fullWidth>
									<Typography>
										<strong>Enquiry Status:</strong>
									</Typography>
									<Select
										labelId="order-status-label"
										variant="standard"
										value={selectedStatus}
										onChange={(e) =>
											handleStatusChange(item.id, e.target.value)
										}
										label="Order Status"
									>
										{Object.keys(statusMapping).map((key) => (
											// console.log("Key is : ",key)
											<MenuItem key={key} value={key}>
												{statusMapping[key]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
                            
						</Grid>

                
                        
					</Paper>
					<div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
						{/* {editMode ? ( */}
							{/* <Button
								variant="contained"
								color="primary"
								onClick={handleSaveClick}
							>
								Save
							</Button> */}
						{/* ) : (
							<IconButton
								variant="outlined"
								color="primary"
								onClick={handleEditClick}
								size="small"
								style={{
									borderRadius: "50%",
									width: 40,
									height: 40,
									padding: 8,
								}}
								sx={{ "&:hover": { backgroundColor: "grey", color: "white" } }}
								aria-label={`Edit item ${item.id}`}
							>
								<EditIcon fontSize="small" />
							</IconButton> */}

					</div>
				</div>
			) : (
				<Typography>No item selected</Typography>
			)}

			{/* Snackbar for showing status update notification */}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				variant="filled"
				severity={snackbarSeverity}
				onClose={handleSnackbarClose}
				message={snackbarMessage}
			>
				 <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          
          {snackbarMessage}
        </Alert>
				</Snackbar>
		</Drawer>
	);
};

export default ItemDrawerEnquiry;
