import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

function NumericInput({ onValueChange, allowDecimals = true, allowNegative = false, min = -Infinity, max = Infinity }) {
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        const newValue = event.target.value;

        // Handle empty string case for clearing the input
        if (newValue === '') {
            setValue(newValue);
            onValueChange(newValue); // Notify parent of the change
            return;
        }

        // Regular expression to match the input based on configuration
        const regex = allowDecimals
            ? allowNegative
                ? /^-?\d*\.?\d*$/
                : /^\d*\.?\d*$/
            : allowNegative
            ? /^-?\d*$/
            : /^\d*$/;

        // Validate the value against the regular expression and range
        if (regex.test(newValue)) {
            const numericValue = parseFloat(newValue);
            if (numericValue >= min && numericValue <= max) {
                setValue(newValue);
                onValueChange(newValue); // Notify parent of the change
            }
        }
    };

    return (
        <TextField
            label="Pincode"
            value={value}
            onChange={handleChange}
            sx={{mb:3}}
            variant="outlined"
            fullWidth
            placeholder="Enter Pincode"
            
        />
    );
}

export default NumericInput;
