import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import { ReactComponent as WhatsAppReactLogo } from "../svgicons/whatsapp-svgrepo-com.svg";
import { LinkedIn } from '@mui/icons-material';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import Logo1 from "../svgicons/ReUrjaLogo_v1.svg";
import { green } from '@mui/material/colors';

export default function PageFooter() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ bgcolor: "lightGreen", height: '0.1rem' }} />
        <br />
        
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12}}>

        {/* Main Category: About */}
        <Grid item xs={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
            <Link to='/home'> 
            <img 
            src={Logo1} 
            alt="Description" 
            style={{ width: '100%', height: 'auto', marginBottom: 16 }} 
             />
            </Link>
            </Box>
        </Grid>

          {/* Main Category: About */}
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
            <Typography align="center" variant="h6" fontWeight="bold" color="green">About</Typography>
            <Divider sx={{ width: '100%', my: 1 ,height: '1px', backgroundColor: 'Green' }} />
            <Box component="ul" sx={{pl: 0, textAlign: 'center', mt: 1, listStyleType: 'none' }}>
                
                <li
                    style={{ 
                        color: 'inherit', // Inherit the color from parent
                        cursor: 'default', // Change cursor to default to indicate it's not a link
                        textDecoration: 'none', // Remove underline
                        padding: '4px 0', // Add some padding for spacing
                        transition: 'color 0.3s' // Smooth transition for hover effect
                      }}
                ><Link to='/aboutus' style={{ fontWeight:600, color: 'green', textDecoration: 'none' }}>About Us</Link></li>
            </Box>
            </Box>
         </Grid>
                    
          {/* Main Category: Contact */}
        <Grid item xs={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
            <Typography align="center" variant="h6" fontWeight="bold" color="green">Contact</Typography>
            <Divider sx={{ width: '100%', my: 1,height: '1px', backgroundColor: 'Green' }} />
            <Box component="ul" sx={{ pl: 0, textAlign: 'center', mt: 1, listStyleType: 'none' }}>
                <li
                    style={{ 
                        color: 'inherit', // Inherit the color from parent
                        cursor: 'default', // Change cursor to default to indicate it's not a link
                        textDecoration: 'none', // Remove underline
                        padding: '4px 0', // Add some padding for spacing
                        transition: 'color 0.3s' // Smooth transition for hover effect
                    }}
                ><Link to='/contactus' style={{ fontWeight:600, color: 'green', textDecoration: 'none' }}>Contact Us</Link></li>
            </Box>
            </Box>
        </Grid>

          {/* Main Category: Legal */}
        <Grid item xs={3}>
            <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" fontWeight="bold" color="green">Legal</Typography>
            <Divider sx={{ width: '100%', my: 1,height: '1px', backgroundColor: 'Green' }} />
            <Box component="ul" sx={{ pl: 0, textAlign: 'center', mt: 1, listStyleType: 'none' }}>
                <li
                    style={{ 
                        color: 'inherit', // Inherit the color from parent
                        cursor: 'default', // Change cursor to default to indicate it's not a link
                        textDecoration: 'none', // Remove underline
                        padding: '4px 0', // Add some padding for spacing
                        transition: 'color 0.3s' // Smooth transition for hover effect
                    }}
                ><Link target='_blank' to='/tnc' style={{fontWeight:600 , color: 'green', textDecoration: 'none' }}>Terms & Privacy Policy</Link></li>
            </Box>
            </Box>
        </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <Box>
              <Typography variant="h6" align="center" fontWeight="bold">Follow Us:</Typography>
              <Box display="flex" justifyContent="center" mt={1}>
                <IconButton href="https://www.linkedin.com/company/reurja" target="_blank" rel="noopener noreferrer">
                  <LinkedIn color='primary' fontSize='large' />
                </IconButton>
                <IconButton href="https://www.youtube.com/@Reurja" target="_blank" rel="noopener noreferrer">
                  <YouTubeIcon color='error' fontSize='large' />
                </IconButton>
                <IconButton href="https://www.facebook.com/groups/Reurja.com" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon color='primary' fontSize='large' />
                </IconButton>
                <IconButton href="https://wa.me/9422652285" target="_blank" rel="noopener noreferrer">
                  <WhatsAppReactLogo />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
        <IconButton>
            <Link to={"/9f5cb"}>
            <Tooltip title="SPLogin">
                <ContactEmergencyIcon />
            </Tooltip>
            </Link>
        </IconButton>
        </Grid>

        </Grid>
        <Box sx={{ bgcolor: 'lightGreen', height: '0.1rem' }} />
        <Box textAlign="center" mt={2}>
          <Typography variant="body2">
            <b><u>Disclaimer:</u></b> Any/all trademarks/names featured on this Website are owned by the respective trademark owners. Where a trademark or brand name is referred to, it is used solely to describe or identify the products and services and is in no way an assertion that such products or services are endorsed by or connected to this website.
          </Typography>
        </Box>

        <Box sx={{ bgcolor: 'lightGreen', height: '0.1rem' }} />
        <br />
      </Box>

      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
    <Typography fontSize={10} sx={{ mb: 0.5 }}>Copyright @2024-25 ReUrja.com</Typography>
    <Typography fontSize={10}>
        Powered by <a href="https://www.omnevu.com" target="_blank" rel="noopener noreferrer">OmneVu.com</a>
    </Typography>
    </Box>

    </>
  );
}
