import { useEffect, useState } from "react";
import ProductThumbNlCard from "../components/ProductThumbNlCard";
import Grid from "@mui/material/Unstable_Grid2";
import { CartState } from "../context/ShopContext";
import { FilteredCards } from "../DatabaseAPI/DatabaseQuery";
import {
  clearSessionProdListByCategory, clearSessionProdListByType,
  getSessionProdListByCategory, getSessionProdListByType
} from "../utils/sessionCheck";
import { GridCardExpand, GridCards } from "../DatabaseAPI/DatabaseQuery";


export default function ProdCardListPg() {
  const { updateProducts, products } = CartState();

  const showProdList = async () => {

    // this method will show the product list for the flow as "select prod type from home page -> login to app
    //-> show the product list". Rest of the Filter usage on filter page will remain same
    // By making this chage we are avoiding a call to DB before anyone's login
    try {

      console.log("value return by getSessionProdListByType is:", getSessionProdListByType());
      console.log("value return by getSessionProdListByCategory is:", getSessionProdListByCategory());

      let productList = null;
      if (getSessionProdListByType() != null) {
        console.log("Calling database query for getSessionProdListByType");
        productList = await GridCards(10, getSessionProdListByType());
        updateProducts(productList);
        clearSessionProdListByType();

      } else if (getSessionProdListByCategory() != null) {
        console.log("Calling database query for getSessionProdListByCategory >>");
        productList = await GridCardExpand(1, 25, getSessionProdListByCategory());
        updateProducts(productList);
        clearSessionProdListByCategory();
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }

  }

  useEffect(()=>{
    console.log("Inside useeffect of ProdCardListPg");
    showProdList();
  },[])

  return (
    <>
      
      {/* <Grid container spacing={2}> */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        {products.map((prod, index) => (
          // <Grid xs={4}>
          <Grid xs={2} sm={4} md={4} key={index}>
            <ProductThumbNlCard prod={prod} key={prod.id} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
