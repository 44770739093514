// ProductDetails.jsx
import React, { useEffect } from "react";
import { Typography, Button, Divider, Box, Breadcrumbs } from "@mui/material";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { CartState } from "../context/ShopContext";
import { useParams } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import UserAlert from "../components/UserAlertMsg";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {green} from "@mui/material/colors";
import { getSessionDeliveryPinCode, getUserEmail } from "../utils/sessionCheck";
import { isProductAvailableAtPincode } from "../DatabaseAPI/DatabaseQuery";

const buttonstyle = { marginLeft: "5px", marginRight: "5px" };

const ProductDetails = () => {
  // let { prodDetailID } = useParams();
  const { cart, setCart, products, prodDetailID, setProdDetailID } =
    CartState();
  const navigateTo = useNavigate();

  const removeFromCart = (prodDetailID) => {
    setCart((currentCart) => {
      // Filter out the item with the matching productId
      const updatedCart = currentCart.filter(
        (item) => item.prodID !== prodDetailID
      );
      return updatedCart;
    });
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const [message, setMessage] = useState("");
  const [deliveryPin, setDeliveryPin] = useState();

  useEffect(()=>
    {
      let pin = getSessionDeliveryPinCode();
      setDeliveryPin(pin);
    },[setDeliveryPin]);

    //updated Add To Cart Button which To check whether product is available or not  called on the button to Add to cart at line 260 
  const handleAddToCart = async () => {
    try {
      // Await the result of the async function
      const isAvailable = await isProductAvailableAtPincode(productFound.prodID, Number(deliveryPin));
      console.log(isAvailable); // This will now be the correct value
      
      if (!isAvailable) {
        alert('Product is not deliverable at your given PinCode');
        return; // Exit the function if the product is not available
      }
  
      // Check if the product is already in the cart
      if (getUserEmail() == null) {
        alert("Please login first");
      } else {
        const existingProductIndex = cart.findIndex(
          (item) => item.prodID === productFound.prodID
        );
  
        if (existingProductIndex !== -1) {
          // If the product is already in the cart, update its quantity
          const updatedCart = [...cart];
          updatedCart[existingProductIndex].qty++;
          setCart(updatedCart);
          setMessage("Item added to cart");
          // Show the Snackbar
          handleSnackbarOpen();
        } else {
          // If the product is not in the cart, add it
          setCart([...cart, { ...productFound, qty: 1 }]);
          setMessage("Item added to cart");
          // Show the Snackbar
          handleSnackbarOpen();
        }
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };



  //buy now handle button to check whether the data is available or not  called on the button to buy now at line 274
  const handleBuyNow = async () =>
    {
      try {
        const isAvailable = await isProductAvailableAtPincode(productFound.prodID, deliveryPin);
  
        if (!isAvailable) {
          alert('Product is not deliverable at your given PinCode');
          return; // Exit the function if the product is not available
        }
  
        if (getUserEmail() == null) {
          alert("Please login first");
        } else {
          const existingProductIndex = cart.findIndex(
            (item) => item.prodID === productFound.prodID
          );if (existingProductIndex !== -1) {
            // If the product is already in the cart, update its quantity
            const updatedCart = [...cart];
            updatedCart[existingProductIndex].qty++;
            setCart(updatedCart);
          } else {
            // If the product is not in the cart, add it
            setCart([...cart, { ...productFound, qty: 1 }]);
          }
          navigateTo("/checkout");
        }
  
        
      } catch (error) {
        console.error('Error adding product to cart:', error);
      }
    }
    


  const productQtyUpdate = [];
  // Replace the following with product data
  // const product = {
  //   name: 'Product Name',
  //   description: 'Product Description',
  //   price: '$9758',
  //   stock: 50,
  //   type:'4W',
  //   brand:'Exide',
  //   ahva:35,
  //   warranty:'42F+35P',
  //   code:'asb212'
  // };
  // console.log("updateproductdetails..", updateProducts);
  console.log("prodetailID==", prodDetailID);
  // const prodID = '9ff7';
  // const productFound = products.find((p) => p.prodID === prodID);
  const productFound = products.find((p) => p.prodID === prodDetailID);
  // setProdDetailID("");
  console.log("founprod==", productFound);

  const [showTestRideButton, setShowTestRideButton] = useState(
    productFound.prodProductCategory === 'EV'
  );


  console.log("cartvalue=", cart);

  // if (!prodID) {
  //   // Handle the case where the product is not found
  //   return <div>Product not found</div>;
  // }
  const priceWithExchange = () => {
    // const exchangePrice = productFound.prodOP1WithGST - productFound.prodIndicativeExchngPrice;
    const exchangePrice = productFound.prodIndicativeExchngPrice;
    return exchangePrice;
  }

  return (
    <>
    

   
    <div>

      

      <Box sx={{ m: 2 }}>

        {/* <Button style={buttonstyle} variant="contained" color="primary" onClick={() => removeFromCart(prodDetailID)}>
        Remove from Cart
      </Button> */}
        {/* Decrement quantity */}
        {/* <Button
            style={buttonstyle}
            variant="contained"
            color="success"
            onClick={() => {
            const existingProductIndex = cart.findIndex(item => item.prodID === productFound.prodID);

            if (existingProductIndex !== -1) {
              const updatedCart = [...cart];
              if (updatedCart[existingProductIndex].qty > 1) {
              updatedCart[existingProductIndex].qty--;
              setCart(updatedCart);
              } else {
              // If quantity is 1, remove the product from the cart
              updatedCart.splice(existingProductIndex, 1);
              setCart(updatedCart);
              }
            }
            }}
          >
          Remove from Cart
          </Button> */}

        {showTestRideButton ? (
          <>
          <Button style={buttonstyle}
          variant="contained"
          color="success" 
          onClick={()=>navigate("/testride")}>
            <BookOnlineIcon></BookOnlineIcon> Book a Test Ride
          </Button>

          <Button style={buttonstyle} 
          variant="contained"
          color="success">
            <ContactSupportIcon></ContactSupportIcon>Contact for more Information
            </Button>
            </>

        ) : (
          <>
            <Button
              style={buttonstyle}
              variant="contained"
              color="success"
              onClick={handleAddToCart}
            >
              Add to Cart
            </Button>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000} // Adjust as needed
              onClose={handleSnackbarClose}
              message={message}
              anchorOrigin={{vertical: 'botton', horizontal: 'center'}}
            />
            <Button
              style={buttonstyle}
              variant="contained"
              color="success"
              onClick={handleBuyNow}
            >
              Buy Now
            </Button>
          </>
        )}
      </Box>

      {/* <h2>{product.prodName}</h2> */}
      {/* Render other product details */}

      <Typography sx={{ color: deepPurple[500] }} variant="h3" gutterBottom>
        {productFound.prodName}
      </Typography>
      <Typography variant="body1" paragraph>
        {productFound.prodDescription}
      </Typography>
      <Divider style={{ margin: "10px 0" }} />
      <Typography variant="h6">Brand: {productFound.prodBrandName}</Typography>
      <Typography variant="h6">
      {/* Ah/VA : {productFound.prodAmpereHourRating}Ah */}
      Capacity : {productFound.prodAmpereHourRating}
      </Typography>
      <Typography variant="h6">
        Voltage: {productFound.prodVoltage}V
      </Typography>
      <Typography variant="h6">Type: {productFound.prodUsageType}</Typography>
      <Typography variant="h6">
        MRP: <b>₹</b>
        {productFound.prodMRP}
      </Typography>
      <Typography variant="h6" sx={{color: green[600] }}>
        Offer Price: <b>₹</b>
        {productFound.prodOP1WithGST}
      </Typography>
      <Typography variant="h6">
      Exchange Price <i>(same Ah & Type)</i>: <b>₹</b>{priceWithExchange()}
      </Typography>
      <Typography variant="h6">
        Warranty: {productFound.prodWarrantyDtls}
      </Typography>
      <Typography variant="h6">
        Dimensions(in mm): {productFound.prodDimensions}
      </Typography>
      <Typography variant="h6">
        Weight(in Kg): {productFound.progWeightInKgs}
      </Typography>
      <Typography variant="h6">
        Constitution: {productFound.prodConstitutionType}
      </Typography>
      <Typography variant="h6">
        Country of Origin: {productFound.prodCountryOfOrigin}
      </Typography>
      <br></br>
      <Divider margin={2}></Divider>
      <br></br>
      <Typography variant="body1">
        Additional Details: {productFound.prodAdditionalDetails}
        <br></br>
        Technical Name: {productFound.prodTechName}
        <br></br>
        Technical Specifications: {productFound.prodTechnicalSpecs}
      </Typography>
      {/* <Typography variant="h6">Code: {productFound.code}</Typography> */}

      {/* <Typography variant="body2" color="textSecondary" paragraph>
        In Stock: {products.stock} units
      </Typography> */}
      {/* <Button style={buttonstyle} variant="contained" color="primary" onClick={() => {
        cart.map(productQtyUpdate)
        {}
        if(productQtyUpdate.prodID === productFound.prodID)
        {productQtyUpdate.qty++}
        else{setCart([...cart, productQtyUpdate]);}
              
            }}>
        Add to Cart
      </Button> */}
      {/* increment quantity */}
    </div>
    </>
  );
};

export default ProductDetails;
