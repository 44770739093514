import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { CartState } from "../context/ShopContext";
import { FilteredCards } from "../DatabaseAPI/DatabaseQuery";
import { Typography } from "@mui/material";
import { getSessionProdListByType } from "../utils/sessionCheck";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { appAnalytics } from "../firebase.config";

export default function Drawers() {
  const [cat, setCat] = useState("");
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const { updateProducts } = CartState();
  // const [type, setType] = useState("2W");
  // const [price, setPrice] = useState("2w");
  // const [brand, setBrand] = useState("2w");

  // const handleTypeChange = (event) => {
  //   setType(event.target.value);
  // };
  // const [pincodeList, setPincodeList] = useState("");
  const citys = process.env.REACT_APP_CITY_LIST.split(",");

  const handleCityChange = (event) => {
    setCity(event.target.value);
    // generatePincodeList();
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  //handles function for the type or catagory of the product filtration function on filter page are declared and used here 
  //START
  const handleTypeChange = (event) => {

    setType(event.target.value);
  };
  const handleCatChange = (event) => {
    setCat(event.target.value);
  };

  const handleBrandChange = (event) => {

    setBrand(Number(event.target.value));
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  //END
  const handleReset = () => {
    setCat("");
    setType("");
    setPrice("");
    setBrand("");
    setCity("");
    setPincode("");
  };

  // const generatePincodeList = () => {
  //   setPincodeList(["411048", "411049", "411050"]);
  // };

  //SUBMIT function handles the filteration for products which are getting displayed FilterCard is decalred in DatabaseQuery
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    console.log(data.get("cat"))
    console.log(data.get("type"));
    console.log(data.get("price"));
    console.log(data.get("brand"));
    console.log(data.get("city"));
    console.log(data.get("pincode"));

    try {

         // capture gAnalytics event of new filter seletion
         const anlyticsString = ("Filter page - New Filter applied ");
         console.log(anlyticsString);
         logEvent(appAnalytics, anlyticsString);
         

      const productList = await FilteredCards(
        data.get("cat"),
        data.get("type"),
        data.get("price"),
        data.get("brand"),
        // data.get("city"),
        data.get("pincode")

      );
      console.log(data);
      updateProducts(productList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      // height={1320}
      height={"auto"}
      maxWidth={250}
      sx={{ border: "2px solid #7ee67e", p: 1 }}
    >
      {/* <Box height={80} width={250} sx={{ border: "2px solid #7ee67e" }}>
        <Typography
          variant="h5"
          component="section"
          sx={{ flexGrow: 1, textAlign: "center", m: 4 }}
        >
          Filters
        </Typography>
      </Box> */}
      <Box sx={{ alignItems: 'center', justifyContent: 'center', m: 2, bgcolor: "lightgreen", p: 1, flexGrow: 1 }}> <b>Select Option(s) and Apply </b></Box>

      <Box maxWidth={250} sx={{ border: "2px solid #7ee67e", mb: 1 }}>

        {/* <Stack spacing={2}>
              
          <FormControl sx={{ p: 2 }}>
            
            <FormLabel id="Category">Category:</FormLabel>
            <RadioGroup
              aria-labelledby="Category"
              id="category"
              name="cat"
              value={cat}
              onChange={handleCatChange}
            >
              <FormControlLabel 
                  value="All" 
                  control={<Radio />} 
                  label="All" 
                  disabled // temporarily disabled for first launch
                  />

              <FormControlLabel
                value="BatteryLeadAcid"
                control={<Radio />}
                label="LeadAcid Battery"
                disabled // temporarily disabled for first launch
              />
              <FormControlLabel
                value="BatteryLithium"
                control={<Radio />}
                label="Lithium Battery"
                disabled    // disabled for first prod reelase in June 2024
              />
              
              <FormControlLabel
                value="Solar"
                control={<Radio />}
                label="Solar"
                disabled   // disabled for first prod reelase in June 2024
              />
              <FormControlLabel value="EV" control={<Radio />} label="EV" 
                disabled   // disabled for first prod reelase in June 2024
              />
            </RadioGroup>
          </FormControl>
        </Stack> */}
      </Box>
      <Box maxWidth={250} sx={{ border: "2px solid #7ee67e", mb: 1 }}>
        <Stack spacing={2}>
          {/* <Typography
                variant="h6"
                component="section"
                sx={{ flexGrow: 1, textAlign: "left", p: 2 }}
              >
                Types:
              </Typography> */}
          <FormControl sx={{ p: 2 }}>
            <FormLabel id="demo-radio-buttons-group-label">Types:</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              // defaultValue="female"
              id="type"
              name="type"
              value={type}
              onChange={handleTypeChange}
            >
              <FormControlLabel value="All" control={<Radio />} label="All" />
              {/* <FormControlLabel value="UPS" control={<Radio />} label="UPS" /> */}
              <FormControlLabel
                //  sx={{ '& .MuiFormControlLabel-label': { fontSize: 12 } }}
                value="InverterBattery"
                control={<Radio />}
                label="Inverter/UPS Battery"
              />

              <FormControlLabel
                value="2W"
                control={<Radio />}
                label="2 Wheeler Battery"
              />
              <FormControlLabel
                value="3W-LCV"
                control={<Radio />}
                label="3 Wheeler Battery"
              />
              <FormControlLabel
                value="CAR-SUV"
                control={<Radio />}
                label="CAR Or SUV Battery"
              />
              <FormControlLabel
                value="BusOrTruck"
                control={<Radio />}
                label="Bus Or Truck Battery"
              />
              <FormControlLabel value="Tractor" control={<Radio />} label="Tractor Battery" />

              <FormControlLabel value="CAR-SUV-3W-TRACTOR-CV" control={<Radio />} label="Common-Car/SUV/3W/ Tractor/CV Battery" />
              <FormControlLabel value="E-RICKSHAW" control={<Radio />} label="E-RICKSHAW Battery" />

              <FormControlLabel
                value="IndustrialBattery"
                control={<Radio />}
                label="Special / Industrial Battery"
              />


              {/* <FormControlLabel value="DM-WATER" control={<Radio />} label="Distilled Water" /> */}
              <FormControlLabel
                value="Inverter"
                control={<Radio />}
                label="Inverter / UPS Unit"
              />

              <FormControlLabel
                value="LiInbuiltInverter"
                control={<Radio />}
                label="Lithium Inbuilt Inverter"
              />
              <FormControlLabel
                value="Li12V"
                control={<Radio />}
                label="Li 12V Battery"
              />
              <FormControlLabel
                value="Li24V"
                control={<Radio />}
                label="Li 24V Battery"
              />
              <FormControlLabel
                value="Li36V"
                control={<Radio />}
                label="Li 36V Battery"
              />
              <FormControlLabel
                value="Li48V"
                control={<Radio />}
                label="Li 48V Battery"
              />
              <FormControlLabel
                value="Li72V"
                control={<Radio />}
                label="Li 72V Battery"
              />

              <FormControlLabel
                value="Li96V"
                control={<Radio />}
                label="Li 96V Battery"
              />

              <FormControlLabel
                value="HP200LTR"
                control={<Radio />}
                label="Heat Pump 200Ltr"
              />

              <FormControlLabel
                value="HP300LTR"
                control={<Radio />}
                label="Heat Pump 300Ltr"
              />

              <FormControlLabel
                value="HP500LTR"
                control={<Radio />}
                label="Heat Pump 500Ltr"
              />


            </RadioGroup>
          </FormControl>
        </Stack>
      </Box>
      <Box maxWidth={250} sx={{ border: "2px solid #7ee67e", mb: 1 }}>
        <Stack spacing={2}>
          {/* <Typography
                variant="h6"
                component="section"
                sx={{ flexGrow: 1, textAlign: "left", p: 2 }}
              >
                Types:
              </Typography> */}
          <FormControl sx={{ p: 2 }}>
            <FormLabel id="demo-radio-buttons-group-label-prices">
              Prices(₹):
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label-prices"
              // defaultValue="female"
              name="price"
              value={price}
              onChange={handlePriceChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="All" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Below 2000"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="2000 to 10000"
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Above 10000"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Box>
      <Box maxWidth={250} sx={{ border: "2px solid #7ee67e", mb: 1 }}>
        <Stack spacing={2}>
          {/* <Typography
                variant="h6"
                component="section"
                sx={{ flexGrow: 1, textAlign: "left", p: 2 }}
              >
                Types:
              </Typography> */}
          <FormControl sx={{ p: 2 }}>
            <FormLabel id="demo-radio-buttons-group-label-brands">
              Brands:
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label-brands"
              // defaultValue="female"
              name="brand"
              value={brand}
              onChange={handleBrandChange}
            >
              <FormControlLabel value="All" control={<Radio />} label="All" />
              <FormControlLabel value="1" control={<Radio />} label="Exide" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Amaron"
                disabled // temporarily disabled for first launch
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Livfast"
                disabled // temporarily disabled for first launch
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="SF Sonic"
                disabled // temporarily disabled for first launch
              />
              <FormControlLabel value="7" control={<Radio />} label="Livgard"
                disabled   // temporarily disabled for first launch
              />
              <FormControlLabel
                value="5"
                control={<Radio />}
                label="Luminious"
                disabled // temporarily disabled for first launch
              />
              <FormControlLabel
                value="6"
                control={<Radio />}
                label="Microtek"
                disabled // temporarily disabled for first launch
              />
              <FormControlLabel value="8" control={<Radio />} label="Okaya"
                disabled   // temporarily disabled for first launch
              />

              <FormControlLabel value="9" control={<Radio />} label="Amaze"
                disabled   // temporarily disabled for first launch
              />
              <FormControlLabel value="10" control={<Radio />} label="Dynex"
                disabled   // temporarily disabled for first launch
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Box>

      {/* ######## START Commented on 4 Jun 24 as we are not using pincode in filters yet ########  */}
      {/* <Box maxWidth={250} sx={{ border: "2px solid #7ee67e", mb: 1 }}>
        <Stack>
          <Typography
            variant="h6"
            component="section"
            sx={{
              flexGrow: 1,
              textAlign: "left",
              px: 2,
              pt: 1,
              fontSize: "1em",
            }}
          >
            Delivery Location:
          </Typography>

          <InputLabel id="pincode" sx={{ px: 2 }}>
            Pincode
          </InputLabel>
          <TextField
            id="pincode"
            labelId="pincode"
            variant="outlined"
            name="pincode"
            value={pincode}
            sx={{ p: 2 }}
            onChange={handlePincodeChange}
          />
        </Stack>
      </Box> */}

      {/* ######## END Commented on 4 Jun 24 as we are not using pincode in filters yet ########  */}

      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          mt: 1,
          mb: 1,
          justifyContent: "center",
          maxWidth: 250,
        }}
      >
        <Button type="submit" variant="contained">
          Apply
        </Button>
        {/* <Button type="button" onClick={handleReset} variant="contained">
          Reset
        </Button> */}
      </Stack>
    </Box>
  );
}
