import * as React from "react";
import Box from "@mui/material/Box";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { useState } from "react";
import {
	Alert,
	Button,
	Paper,
	Snackbar,
	Stack,
	styled,
	Tooltip,
	useMediaQuery,
} from "@mui/material";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CartState } from "../context/ShopContext";
import {
	getUserEmail,
	clearAllSessionObjects,
	getUserDisplayName,
} from "../utils/sessionCheck";
import IconButton from "@mui/material/IconButton";
import Logo1 from "../svgicons/ReUrjaLogo_v1.svg";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect } from "react";
import { getSessionDeliveryPinCode } from "../utils/sessionCheck";
import PincodePopup from "./PincodePopup";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import LoginIcon from '@mui/icons-material/Login';
import { green, lightGreen } from "@mui/material/colors";
import { Logout } from "@mui/icons-material";
import DrawerMenu from "./DrawerMenu";
import MenuIcon from '@mui/icons-material/Menu';

// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();
export default function NavBar2(props) {
	const [selectedPinCode, setSelectedPinCode] = useState("");
	const [open, setOpen] = useState(false);
	const [displayName, setDisplayName] = useState("");
	//Snackbar handling
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState('');


	const [anchorEnquiry, setAnchorEnquiry] = useState(null);
	const navigate = useNavigate();

	const { cart, setCart, updateProducts } = CartState();//cart instance called through context api
	const totalItemsInCart = cart.reduce((total, item) => total + item.qty, 0);//Updating count of cart

	//function to navigate to home
	const handleHomeClick = () => {
		navigate("/home");
	};


	const handleEnquiryMenuClick = (event) => {
		setAnchorEnquiry(event.currentTarget);
	};
	const handleEnquiryMenuClose = () => {
		setAnchorEnquiry(null);
	};


	//useNavigate is the hook provided for redirection
	//function to navigate to orders of customer
	const handleTrackOrderClick = (event) => {
		navigate("/orders");
	};


	//function to navigate to knowBs of customer
	const handleKnowledbaseClick = () => {
		navigate("/knowBs");
	};


	// const handleEnquiryClick = (type) => {
	// 	if(!getUserEmail()){
	// 		navigate("/enq", { state: { formType: type } });
	// 	}
		
		
	// };
	const handleEnquiryClick = (type) => {
		navigate("/enq", { state:{formType:type} });
	};
	
//logout function which simply clearsession objects as well as empty cart and navigate to home page
	const handleLogout = () => {
		clearAllSessionObjects();
		updateProducts([]);
		setCart([]);
		navigate("/");
	};

	useEffect(() => {
		// Function to fetch and set pincodes
		let cd = getUserDisplayName();
		// console.log("Display Name:", cd);
		setDisplayName(cd);
		// console.log("Display Name:", displayName);
		let cd1 = getSessionDeliveryPinCode();
		setSelectedPinCode(cd1);
		// console.log("Selected Pincode:", selectedPinCode);
	}, [displayName, selectedPinCode]);

	const handleClickAway = () => {
		setOpen(false);
	};

	const style = {
		color: "#127008",
		fontWeight: "bold",
		position: "absolute",
		left: "75%",
		top: "25px",
	};
	const style2 = {
		color: "#127008",
		fontWeight: "bold",
		marginTop:"20px",
		height:"3px"
		
	};
	
	// Start handles snack bar open and close functionality through states
	const handleSnackbarOpen = () => {
		setSnackbarOpen(true);
	  };
	  const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	  };
	  //END

	  //checking for Cart data if cart has any object then redirecting to the cart else keeping user on home page 
	  const handlecart = () =>
	  {
		if(totalItemsInCart>0)
		{
			navigate('/checkout')
			return true;
		}
		else{
			setMessage("Cart is empty");
			setSeverity("error");
			handleSnackbarOpen();
			return false;
		}
	  }

	  const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		display: 'flex', // Use Flexbox
		alignItems: 'center', // Center items vertically
		justifyContent: 'center', // Center items horizontally (if needed)
		color: theme.palette.text.secondary,
		border: 'none', // Remove border
		fontWeight: 'bold', // Make font weight bold
		fontSize: '1rem',
		cursor: 'pointer', // Change mouse pointer to hand on hover
		...theme.applyStyles('dark', {
		  backgroundColor: '#1A2027',
		}),
	  }));

	  const LeftAlignedItem = styled(Paper)(({ theme }) => ({
		backgroundColor: '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		display: 'flex', // Use Flexbox
		alignItems: 'center', // Center items vertically
		justifyContent: 'flex-start', // Align items to the left
		color: theme.palette.text.secondary,
		border: 'none', // Remove border
		fontWeight: 'bold', // Make font weight bold
		fontSize: '1rem',
		cursor: 'pointer', // Change mouse pointer to hand on hover
		...theme.applyStyles('dark', {
		  backgroundColor: '#1A2027',
		}),
	  }));

	  const isMobile = useMediaQuery('(max-width:768px)');
	  
	  const isadjust = useMediaQuery('(min-width:1160px)');

	  const [drawerOpen, setDrawerOpen] = useState(false);
	  const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
		  return;
		}
		setDrawerOpen(open);
	  };

	//return Statement conatains all elements which are present in navbar
	return (
		
		<Box>
			<Box
				 display="flex"
				 alignItems="center"
				 justifyContent="space-between"
				 flexDirection="row"
				 p={2}
				// sx={{ border: "2px solid grey" }}
			>
				<Link to={"/home"}>
					{/* <img */}
					<Box
						// component="section"
						component="img"
						alt="ReUrja.com"
						src={Logo1}
						sx={{
						height: { xs: '55%', sm: '55%' },
						width: { xs: '55%', sm: '55%' },
						}}
					></Box>
					{/* </img> */}
				</Link>
				<Box  display={props.pinCodeBoxDisplay} sx={{ p: 2 }}>
					{getSessionDeliveryPinCode() === 0 ? null : ( 
						<Button onClick={() => setOpen(true)} variant="text" style={isMobile ? { fontSize:"10px", color: "#127008",fontWeight: "bold"} :
						{color: "#127008",fontWeight: "bold"}
						}>
						Delivery Pincode: {selectedPinCode}
					</Button>)}
					{/* Integrating PincodePopup component */}
					{open && (
						<ClickAwayListener onClickAway={handleClickAway}>
							<PincodePopup
								trigger={open}
								setTrigger={setOpen}
								setSelectedPinCode={setSelectedPinCode}
							/>
						</ClickAwayListener>
					)}
				</Box>

				 {getUserEmail() ? (

					
          <Box  display="flex" gap={2} sx={{ p: 2 }}>
            
            <Tooltip title="View Cart">
            {/* <IconButton aria-label="delete" size="large" sx={{display:cartDisplay}}> */}
            <IconButton aria-label="delete"    
				style={isMobile ?{ height: '24px', width: '24px' }: { height: '45px', width: '45px' }}
			sx={{display:props.cartDisplayProp}}>
				<div onClick={handlecart}>
					<Link>
					<Badge badgeContent={totalItemsInCart}  color="success">
                  <ShoppingCartIcon  style={isMobile ?{ height: '15px', width: '15px' }:{ height: '35px', width: '35px' }}></ShoppingCartIcon>
                </Badge>
				</Link>
				
				</div>
				{/* <Link to={"/checkout"} >
                
              </Link> */}

              <Snackbar
					open={snackbarOpen}
					autoHideDuration={3000}
					onClose={handleSnackbarClose}
					variant="filled"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					 <Alert
						onClose={handleSnackbarClose}
						severity={severity} 
						variant="filled"
						sx={{ width: '100%' }}
						>
						{message}
						</Alert>


				</Snackbar>
            </IconButton>
            </Tooltip>
            <Tooltip title="Logout" >
            <IconButton aria-label="logout" 
			style={isMobile ?{ height: '24px', width: '24px' }: { height: '45px', width: '45px' }
		}
			onClick={handleLogout}>
              {/* <ExitToAppIcon /> */}
			  <Logout style={isMobile ?{ height: '15px', width: '15px' }:
				{ height: '35px', width: '35px' }
			} />
            </IconButton>
            </Tooltip>
		
          </Box>

		  
        ) : (
         
			<Tooltip title="Login">
			<IconButton style={isMobile ?{ height: '24px', width: '24px' }: { height: '45px', width: '45px' }
			} sx={{backgroundColor:green[100]}}
				onClick={()=>{
					navigate("/login")
				}
				}
			>
				<LoginIcon style={isMobile ?{ height: '15px', width: '15px' }:
				{ height: '35px', width: '35px' }
			}></LoginIcon>
				
            	{/* <Link to="/login">LogIn</Link>  */}
			</IconButton>
			</Tooltip>

		
          
        )}
			</Box>

			{/* <Divider variant="middle" sx={{ p: 1, borderBottomWidth: 2, bgcolor:"green" }}></Divider> */}
			<Divider sx={{ bgcolor: "green", mt: 2 }} ></Divider>
			
			<Stack
				direction="row"
				spacing={2}
				sx={isadjust ? { width: '100%', marginTop:"-10px" } : { width: '100%', marginTop:"0px" }}
				
				// sx={{ border: "2px solid grey" }}
			>
				{isMobile ? 
					<IconButton
					edge="start"
					color="inherit"
					aria-label="menu"
					onClick={toggleDrawer(true)}
				  >
					<MenuIcon />
				  </IconButton>
				: <Toolbar sx={{ display: props.showMenuBarProp }}>
					<Item
						color="inherit"
						variant="text"
						onClick={handleHomeClick}
						sx={{  color: "#f79646", fontWeight: "bold" }}
					>
						<HomeIcon style={{ marginRight: 8 }} />
						
						Home
					</Item>
					

					
				
				<Typography sx={{ color: "green", mx: 1 }}>|</Typography>
					<Item
					color="inherit"
					variant="text"
					onClick={handleEnquiryMenuClick}
					sx={{ color: "#127008", fontWeight: "bold" }}
				>
					Enquiry Forms
					<Typography variant="caption" sx={{ color: "orange" }}>&nbsp;v</Typography>
					
				</Item>
				
					<Menu
						anchorEl={anchorEnquiry}
						open={Boolean(anchorEnquiry)}
						onClose={handleEnquiryMenuClose}
						sx={{
							// Manual positioning
							position: 'absolute',
							top: '150px', // Adjust as needed
							left: '120px', // Adjust as needed
							maxHeight: '400px', // Optional: Limit height
							width: '300px', // Optional: Set width
						}}
		
					>
						<MenuItem onClick={() => handleEnquiryClick("On Grid Solar")}>
							On Grid Solar
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Green Open Access")}>
							Green Open Access
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Off Grid Solar")}>
							Off Grid Solar
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Solar Plant Project Management")}>
							Project Management
						</MenuItem>
						<Divider/>
						
						<MenuItem onClick={() => handleEnquiryClick("AMC for Battery")}>
							Battery AMC
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("AMC for Solar Plant")}>
							Solar AMC
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("AMC for Diesel Generator Set")}>
							DG Set AMC
						</MenuItem>
						{/* <MenuItem onClick={() => handleEnquiryClick("AMC for CCTV System")}>
							CCTV AMC
						</MenuItem> */}
						<Divider/>

						<MenuItem onClick={() => handleEnquiryClick("Bulk LED Requirements")}>
							LED
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Automation Requirements")}>
							Automation
						</MenuItem>
						{/* <MenuItem onClick={() => handleEnquiryClick("Carbon Credit Requirements")}>
							CO2 Credits
						</MenuItem> */}
						<Divider/>

						<MenuItem onClick={() => handleEnquiryClick("Energy Audit")}>
							Energy Audit
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Green Energy Consultancy")}>
							Green Energy Consultancy
						</MenuItem>
						<MenuItem onClick={() => handleEnquiryClick("Training Requirements")}>
							Training
						</MenuItem>
					</Menu>
					<Typography sx={{ color: "green", mx: 1 }}>|</Typography>
					<Item
						color="inherit"
						variant="text"
						onClick={handleTrackOrderClick}
						sx={{ color: "#127008", fontWeight: "bold" }}
					>
						Track Orders
					</Item>

					<Typography sx={{ color: "green" }}>|</Typography>

					<Item
						variant="text"
						sx={{ color: "#127008", fontWeight: "bold" }}
						onClick={handleKnowledbaseClick}
					>
						Knowledge base
					</Item>
					<Box />
					

				</Toolbar>}
				<DrawerMenu open={drawerOpen} toggleDrawer={toggleDrawer}  />
				{/* Pincode section */}
					
				
					{isMobile ?<Box
						 display="flex" justifyContent="flex-end" width="100%"
						
					>
					{getUserEmail() ? 
					(
							(props.isAdmin || props.isOps) ? 
							(
									props.isAdmin ? (
														<Item variant="body2" sx={style2}>
																		Welcome, &nbsp; Admin
																	</Item>
							)
							:(
											<Item variant="body2" sx={style2}>
											Welcome, &nbsp; Ops
										</Item>
						)):(
							<Item variant="body2" sx={style2}>
							Welcome, &nbsp;{displayName}
							</Item>
						)
						) : (
						<Typography></Typography>
					)}
					</Box> : <Box
						 display="flex" justifyContent="flex-end" width="50%"
						
					>
					{getUserEmail() ? 
					(
							(props.isAdmin || props.isOps) ? 
							(
									props.isAdmin ? (
														<Item variant="body2" sx={style2}>
																		Welcome, &nbsp; Admin
																	</Item>
							)
							:(
											<Item variant="body2" sx={style2}>
											Welcome, &nbsp; Ops
										</Item>
						)):(
							<Item variant="body2" sx={style2}>
							Welcome, &nbsp;{displayName}
							</Item>
						)
						) : (
						<Typography></Typography>
					)}
					</Box>}
					
					
				
			</Stack>
			{/* <Divider variant="middle" sx={{ p:1, borderBottomWidth:5, mb:1 }}></Divider> */}
			{isadjust ? <Divider
				sx={{ bgcolor: "green", mt: -1 }}
			></Divider>:<Divider
			sx={{ bgcolor: "green", mt: 0 }}
		></Divider>}
		</Box>
		
		// </AppBar>
	);
}
