import React, { useEffect } from 'react';
import axios from 'axios';

const AdminMails = ( email, apiKey, orderReferenceID, orderDetails, orderStatus ) => { // Destructure props correctly

  orderDetails.map(item => {
    console.log(item)
  })
  console.log("Order details",orderDetails)
  const sendEmail = async (jsonPayload) => {
    const formData = new FormData();
    formData.append('jsonPayload', JSON.stringify(jsonPayload));
    // console.log("Order Details",orderDetails)

    try {
      const response = await axios.post("https://email-a306.onrender.com/send-email", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log('Email sent successfully!');
      } else {
        console.log('Error sending email.');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.log('Invalid API key');
      } else {
        console.log('Error sending email.');
      }
    }
  };

const sendOrderConfirmationEmail = async () => {
  const jsonPayload = {
    email,
    subject: `Confirmation - Order Reference -- ${orderDetails[0].ordID}`,
    textbody: `Thank you for your order. Your order ID is ${orderReferenceID}.`,
    htmlbody: `
      <div style="font-family: Arial, sans-serif; color: #333;">
        <h2 style="color: #4CAF50;">Thank you for your order!</h2>
        <h4>Your Order ID is <strong>${orderDetails[0].ordID}</strong>.</h4>
        <h3>Order Details:</h3>
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr style="background-color: #f2f2f2;">
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Order Id</th>
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Product Name</th>
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Quantity</th>
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Price</th>
            </tr>
          </thead>
          <tbody>
            ${orderDetails.map(item => `
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;"><strong>${item.ordID}</strong>.</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${item.ordProdName}</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${item.ordQty}</td>
                <td style="padding: 8px; border: 1px solid #ddd;">₹ ${item.ordSubTotal }</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
        <p><strong>Total Amount Payable:</strong> ₹ ${orderDetails[0].ordSubTotal}</p>
        <h4>Your Overall order Reference ID is <strong>${orderReferenceID}</strong>.</h4>
        <h3>Order Tracking:</h3>
        <p>You can track your order using the following link: <a href="${process.env.REACT_APP_PRIMARY_DOMAIN}/orders" style="color: #4CAF50;">Track Order</a></p>
      </div>
    `,
    apiKey,
  };

  await sendEmail(jsonPayload);
};

const sendOrderCancellationEmail = async () => {
  const jsonPayload = {
    email,
    subject: `Cancellation - Order ID -- ${orderDetails[0].ordID}`,
    textbody: `Your order with ID ${orderReferenceID} has been cancelled.`,
    htmlbody: `
      <div style="font-family: Arial, sans-serif; color: #333;">
        <h2 style="color: #ff0012;">Your order with ID ${orderDetails[0].ordID} has been cancelled.!</h2>
        <h3>Order Details:</h3>
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr style="background-color: #f2f2f2;">
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Order Id</th>
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Product Name</th>
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Quantity</th>
              <th style="padding: 8px; text-align: left; border: 1px solid #ddd;">Price</th>
            </tr>
          </thead>
          <tbody>
            ${orderDetails.map(item => `
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;"><strong>${item.ordID}</strong>.</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${item.ordProdName}</td>
                <td style="padding: 8px; border: 1px solid #ddd;">${item.ordQty}</td>
                <td style="padding: 8px; border: 1px solid #ddd;">₹ ${item.ordSubTotal}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>  
        <h4>Your Overall order Reference ID is <strong>${orderReferenceID}</strong>.</h4>
      </div>
    `,
    apiKey,
  };

  await sendEmail(jsonPayload);
};

const Mailer = () => {
  if (orderStatus === 2) {
    sendOrderConfirmationEmail();
    console.log("Order confirmation email sent.");
  } else if (orderStatus === 5) {
    sendOrderCancellationEmail();
    console.log("Order cancellation email sent.");
  } else {
    console.log("No email sent.");
  }
};

  // console.log("Email props:", email, apiKey, orderReferenceID, orderDetails, orderStatus);
  const res = Mailer();
  // console.log("Email response:", res);
  
  
  

  return null;
};

export default AdminMails;
