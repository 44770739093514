import { Grid, Paper } from '@mui/material';
import { useState } from 'react';
import { storage } from '../firebase.config';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';

const Carousel2 = ({ setImages }) => {
  const [imageList, setImageList] = useState([]);
  const imageListRef = ref(storage, 'carousel');

  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => {
            if (!prev.includes(url)) {
              return [...prev, url];
            }
            return prev;
          });
        });
      });
    });
  }, []);

  function Item(props) {
    return (
      <Paper sx={{ justifyItems: "center", width: "100%" }}>
        <img
          src={props.item}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="solar"
        />
      </Paper>
    );
  }

  return (
    <>
     <Carousel sx={{ height: "auto", backgroundColor: "none" }}>
        {imageList.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </>
  ); 
};

export default Carousel2;
