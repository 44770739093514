import React, { useEffect, useState } from 'react';
import { storage } from '../../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { nanoid } from 'nanoid';
import NavBar2 from '../../components/Navbar2';
import { Grid, Stack,Button,Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, Alert } from '@mui/material';
import PageFooter from '../../footer/PageFooter';
import { getIsAdmin } from '../../utils/sessionCheck';
import { Navigate, useNavigate } from 'react-router-dom';

const CarouselConfig = () => {
  const [imageUpload, setImageUpload] = useState(null); 
  const [imageList, setImageList] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);// To track the hovered image index
  const [alertopen, setAlertOpen] = useState(false); 
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  const imageListRef = ref(storage, 'carousel');

  const navigate = useNavigate();

  useEffect(() =>
    {
      const res = getIsAdmin()
      if(!res){
        navigate("/9f5cb")
      }
    }  
  ,[])
  const uploadImage = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `carousel/${imageUpload.name + nanoid(7)}`);
    try {
      uploadBytesResumable(imageRef, imageUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
        setSnackbarMessage("Image Uploaded Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setImageUpload(null);
      });
      
    } catch (error) {
      setSnackbarMessage("Image Upload Failed");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const deleteImage = (url) => {
    // Get the reference to the image from the URL
    const imageRef = ref(storage, url);
    // Delete the image from Firebase Storage
    deleteObject(imageRef)
      .then(() => {
        // Remove the image from the imageList state
        setImageList((prev) => prev.filter((imageUrl) => imageUrl !== url));
        
        handleCloseAlert();
          setSnackbarMessage("Image Deleted Successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('Error deleting image:', error);
        setSnackbarMessage("Error deleting image");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleClickOpen = (url) => {
    setHoveredIndex(url);
    setAlertOpen(true);
  };
  //Handle Close Dialog Box  
  const handleCloseAlert = () => {
    setAlertOpen(false);
    setHoveredIndex(null);
  };

  const deleter = () =>{
    deleteImage(hoveredIndex);
    setAlertOpen(false);
    
  }

  


  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => {
            if (!prev.includes(url)) {
              return [...prev, url];
            }
            return prev;
          });
        });
      });
    });
  }, [imageUpload]);

  return (
    <>
      <NavBar2 />
      <Box maxWidth="xl" height="auto" margin={2} >
      <input type="file" onChange={(e) => setImageUpload(e.target.files[0])} accept=".png, .jpg, .jpeg" />
      <br/>
      <Button variant="contained"
          sx={{ mt: 2 }}
          color="success"
          onClick={uploadImage}>Upload Image</Button>
           <h4> Upload .jpeg/.jpg/.png Files Only</h4>
        </Box>
      
      <h1>Current Carousel: </h1>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid container spacing={2}>

        {imageList.map((url, index) => (
          <div
            key={index}
            style={{ position: 'relative', margin: '10px' }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img style={{ width: 300 }} src={url} alt="carousel" />
            
            {/* Show delete button on hover */}
            {hoveredIndex === index && (
              <button
                onClick={() => handleClickOpen(url)}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  padding: '5px',
                  cursor: 'pointer',
                }}
              >
                Delete
              </button>
            
            )}
          </div>
        ))}
        </Grid>
        <>
          <Snackbar
            anchorOrigin={{  vertical: 'top', horizontal: 'centre'  }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        variant="filled"
      >
        <Alert onClose={() => setSnackbarOpen(false)} anchorOrigin={{  vertical: 'bottom', horizontal: 'center'  }} variant="filled" severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
        <Dialog open={alertopen} onClose={handleCloseAlert}>
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete this enquiry? This action cannot be undone.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseAlert} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={deleter} color="error">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog></>
      </div>
      <PageFooter/>
    </>
  );
};

export default CarouselConfig;
